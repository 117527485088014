import React, { CSSProperties } from "react"
import {
  Typography,
  Paper,
  Grid,
  Box,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material"
import { Prompt } from "./CreateRatingPage"
import { centeredStyle } from "./global"
import { RatingButtonGroup } from "./widgets/RatingButtonGroup"
import { TextField } from "./components/TextField"

interface SurveySectionProps {
  sectionId: number
  title: string
  prompts: Prompt[]
}

export const levelsOfAgreement = [
  "Strongly disagree",
  "Disagree",
  "Somewhat disagree",
  "Neither agree or disagree",
  "Somewhat agree",
  "Agree",
  "Strongly agree",
]

const columnHeaderStyle = {
  width: 120,
  ...centeredStyle,
  justifyContent: "end",
}

const columnHeaderTextStyle: CSSProperties = {
  display: "inline-block",
  width: "100px",
  textAlign: "center",
}

const promptCellStyle = {
  width: { xs: "auto", md: 300 },
  padding: {
    xs: "10px 0px",
    md: "10px 20px 10px 0px",
  },
}

const SurveySection: React.FC<SurveySectionProps> = ({
  sectionId,
  title,
  prompts,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)")

  return (
    <Paper elevation={3} sx={{ padding: { xs: 2, md: "40px" } }}>
      <Typography variant="h5" sx={{ marginBottom: { xs: 1 } }}>
        {title}
      </Typography>
      <Box sx={{ padding: { md: "20px 0px 0px 10px" } }}>
        {!isSmallScreen && (
          <div style={{ display: "flex" }}>
            <Box sx={promptCellStyle}></Box>
            {levelsOfAgreement.map((level, i) => (
              <div style={columnHeaderStyle} key={i}>
                <Typography variant="body1" style={columnHeaderTextStyle}>
                  {level}
                </Typography>
              </div>
            ))}
          </div>
        )}
        {prompts.map((prompt, promptIndex) => (
          <Grid
            container
            style={{ display: "flex", alignItems: "center" }}
            key={promptIndex}
          >
            <Box sx={promptCellStyle}>
              <Typography variant="body1">{prompt.text}</Typography>
            </Box>
            {!isSmallScreen ? (
              <div>
                <RatingButtonGroup promptId={prompt.id} />
              </div>
            ) : (
              <Grid item style={{ width: "100%" }} mb={3}>
                <Select
                  style={{ width: "100%" }}
                  required
                  defaultValue={""}
                  name={`prompt-${prompt.id}`}
                >
                  {levelsOfAgreement.map((level, i) => (
                    <MenuItem key={i} value={i}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
          </Grid>
        ))}
        <Typography sx={{ margin: { md: "35px 0px 10px" } }}>
          Add examples or stories here to support your answer.
        </Typography>
        <TextField
          style={{ width: "95%", padding: "10px 0px" }}
          placeholder="Include any examples or anecdotes to support your answers here."
          name={`comment-${sectionId}`}
          multiline
          rows={6}
        />
      </Box>
    </Paper>
  )
}

export default SurveySection
