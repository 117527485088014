import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { supabase } from "./supabase"
import {
  EMAIL_TRIGGERS_TABLE,
  SIGN_UP_TYPE,
  INVITE_TO_REVIEW_TYPE,
  PRE_REVIEW_PATH,
  GET_NEW_SIGN_IN_LINK_TYPE,
  HOME_PATH,
} from "./constants"
import { Page } from "./Page"
import { Button } from "@mui/material"
import { centeredStyle } from "./global"
import { TextField } from "./components/TextField"

export interface EmailTrigger {
  id: number
  type: string
  email: string
  environment: string
}

export interface SignUpEmailTrigger extends EmailTrigger {
  type: typeof SIGN_UP_TYPE
  principalId: number
}

export interface InviteEmailTrigger extends EmailTrigger {
  type: typeof INVITE_TO_REVIEW_TYPE
  principalId: number
  inviteSenderId?: string
}

export interface NewSignInEmailTrigger extends EmailTrigger {
  type: typeof GET_NEW_SIGN_IN_LINK_TYPE
}

function isSignUp(value: any): value is SignUpEmailTrigger {
  return value.type === SIGN_UP_TYPE
}

function isInvite(value: any): value is InviteEmailTrigger {
  return value.type === INVITE_TO_REVIEW_TYPE
}

function isNewSignInLink(value: any): value is NewSignInEmailTrigger {
  return value.type === GET_NEW_SIGN_IN_LINK_TYPE
}

const LoginHandler: React.FC = () => {
  const navigate = useNavigate()
  const [isLinkExpired, setIsLinkExpired] = useState(false)
  const [addressForRenewalEmail, setAddressForRenewalEmail] = useState<
    string | null
  >()

  useEffect(() => {
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      if (session?.user.email) {
        const { data: emailTriggers } = (await supabase
          .from(EMAIL_TRIGGERS_TABLE)
          .select("*")
          .eq("email", session.user.email)
          .in("type", [
            SIGN_UP_TYPE,
            INVITE_TO_REVIEW_TYPE,
            GET_NEW_SIGN_IN_LINK_TYPE,
          ])
          .order("createdAt", { ascending: false })
          .limit(1)) as { data: EmailTrigger[] }

        const emailTrigger = emailTriggers?.[0]

        if (!emailTrigger) {
          throw new Error(`No email trigger found for ${session.user.email}!`)
        }

        if (isSignUp(emailTrigger) || isInvite(emailTrigger)) {
          navigate(`${PRE_REVIEW_PATH}/${emailTrigger.principalId}`)
        } else if (isNewSignInLink(emailTrigger)) {
          const { data: emailTriggers } = (await supabase
            .from(EMAIL_TRIGGERS_TABLE)
            .select("*")
            .eq("email", session.user.email)
            .eq("type", INVITE_TO_REVIEW_TYPE)
            .order("createdAt", { ascending: false })
            .limit(1)) as { data: InviteEmailTrigger[] }

          const emailTrigger = emailTriggers?.[0]
          const principalId = emailTrigger?.principalId

          if (principalId) {
            navigate(`${PRE_REVIEW_PATH}/${emailTrigger.principalId}`)
          } else {
            navigate(HOME_PATH)
          }
        } else {
          throw new Error(`Unknown email trigger type: ${emailTrigger.type}`)
        }
      } else {
        setIsLinkExpired(true)
      }
    })
  }, [])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const email = formData.get("email") as string

    await supabase.from(EMAIL_TRIGGERS_TABLE).insert({
      email,
      type: GET_NEW_SIGN_IN_LINK_TYPE,
      environment: process.env.NODE_ENV,
    })
    setAddressForRenewalEmail(email)
  }

  if (isLinkExpired) {
    if (!addressForRenewalEmail) {
      return (
        <Page
          title="This Link Has Expired"
          subtitle="The link you used has expired. Please enter your email to request a new one."
        >
          <form
            onSubmit={handleSubmit}
            style={{ ...centeredStyle, width: 300 }}
          >
            <TextField
              label="Email"
              type="email"
              name="email"
              fullWidth
              margin="normal"
              required
            />
            <Button type="submit" variant="contained" color="primary">
              Get New Link
            </Button>
          </form>
        </Page>
      )
    } else {
      return (
        <Page
          title={`New link sent to ${addressForRenewalEmail}`}
          subtitle={`Please check your inbox! You may need to check your spam folder.`}
        />
      )
    }
  }

  return <> </>
}

export default LoginHandler
