import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { supabase } from "./supabase"
import { Page } from "./Page"
import LoadingPage from "./LoadingPage"

export const TokenHandler = () => {
  const { tokenId } = useParams()
  const [didError, setDidError] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (tokenId !== "") {
        const { data } = await supabase.functions.invoke(
          "generate-magic-link",
          {
            body: { tokenId },
          },
        )
        if (data) {
          window.location.href = data.signInLink
        } else {
          setDidError(true)
        }
      }
    })()
  }, [])

  return didError ? (
    <Page>
      <div>Oops! There was an issue signing you in.</div>
    </Page>
  ) : (
    <LoadingPage />
  )
}
