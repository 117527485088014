import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material"
import { TextField } from "./TextField"
import { centeredStyle } from "../global"
import { states as STATES } from "../utils/statesConstants"
import { GET_STARTED_LINK_Z_INDEX_ADD } from "../widgets/NavBarMenu"
import {
  EMAIL_TRIGGERS_TABLE,
  SIGN_UP_FOR_STATE_READY_NOTIFICATION_TYPE,
  NOTIFICATION_SIGN_UPS_TABLE,
  STATE_READY_NOTIF,
} from "../constants"
import { supabase } from "../supabase"
import { useState } from "react"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  flexDirection: "column",
}

const marginBottom = { marginBottom: 20 }

export interface WaitlistSignUp {
  name: string
  email: string
  state: string
}

interface StateReadySignUpModalProps {
  isOpen: boolean
  onClose: () => void
}

export const StateReadySignUpModal = (props: StateReadySignUpModalProps) => {
  const handleSubmit = async ({ name, email, state }: WaitlistSignUp) => {
    await supabase
      .from(NOTIFICATION_SIGN_UPS_TABLE)
      .insert([{ name, email, extra: { state }, type: STATE_READY_NOTIF }])

    await supabase.from(EMAIL_TRIGGERS_TABLE).insert([
      {
        type: SIGN_UP_FOR_STATE_READY_NOTIFICATION_TYPE,
        email,
        environment: process.env.NODE_ENV,
      },
    ])
  }

  return <StateReadySignUpModal_ onSubmit={handleSubmit} {...props} />
}

interface StateReadySignUpModalProps_ extends StateReadySignUpModalProps {
  onSubmit: (args: WaitlistSignUp) => Promise<void>
}

export const StateReadySignUpModal_ = ({
  isOpen,
  onSubmit,
  onClose,
}: StateReadySignUpModalProps_) => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const name = formData.get("name") as string
    const email = formData.get("email") as string
    const state = formData.get("state") as string
    await onSubmit({ name, email, state })
    setIsSubmitted(true)
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 1,
      }}
    >
      <Box sx={style}>
        {isSubmitted ? (
          <Typography variant="h6">
            Thank you for signing up! We'll notify you when your state is ready!
          </Typography>
        ) : (
          <>
            <Typography variant="h6" style={marginBottom}>
              Please enter your info and select a state to be notified when that
              state is ready!
            </Typography>
            <form style={centeredStyle} onSubmit={handleSubmit}>
              <TextField
                id="name"
                name="name"
                label="Name"
                style={marginBottom}
                fullWidth
                required
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                style={marginBottom}
                fullWidth
                required
              />
              <FormControl fullWidth style={marginBottom} required>
                <InputLabel id="state-label">State</InputLabel>

                <Select
                  id="state"
                  labelId="state-label"
                  label="State"
                  name="state"
                  MenuProps={{
                    sx: {
                      zIndex: (theme) =>
                        theme.zIndex.modal + GET_STARTED_LINK_Z_INDEX_ADD + 2,
                    },
                  }}
                >
                  {STATES.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </form>
          </>
        )}
      </Box>
    </Modal>
  )
}
