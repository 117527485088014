import { Typography } from "@mui/material"
import { Principal } from "./FindPrincipalPage"
import { Page } from "./Page"
import { ReportPrincipalProblemWidget } from "./ReportPrincipalProblemWidget"
import { ReviewOrInviteWidget } from "./ReviewOrInviteWidget"

interface NotReadyPrincipalPageProps {
  principal: Principal
  submissionCount: number
}

const NotReadyPrincipalPage: React.FC<NotReadyPrincipalPageProps> = ({
  principal,
  submissionCount,
}) => {
  return (
    <Page
      title={`${principal.fullName} at ${principal.school}`}
      id="not-ready-principal-page"
    >
      <Typography variant="h6">
        {submissionCount > 2
          ? `We just need one or two more teachers to review this principal for this review to be posted!`
          : submissionCount > 0
            ? `We need a few more teachers to review this principal before we can show reviews.`
            : `Be the first to review this principal!`}
      </Typography>
      <Typography variant="body1" style={{ textAlign: "center" }}>
        Reviews are hidden until we have enough submissions to protect the
        privacy of our reviewers.
      </Typography>
      <ReviewOrInviteWidget principal={principal} />
      <ReportPrincipalProblemWidget />
    </Page>
  )
}

export default NotReadyPrincipalPage
