import { Button, Snackbar, Stack, Typography } from "@mui/material"
import { Page } from "./Page"
import {
  FIND_A_PRINCIPAL_PATH,
  PRINCIPALS_BY_RATING_PATH,
  SEARCH_DISTRICTS_PATH,
  SURPRISE_ME_PATH,
} from "./constants"
import { useState } from "react"
import { useParams } from "react-router-dom"

export const HomePage = () => {
  const { showError } = useParams()

  return <HomePage_ showError={!!showError} />
}

export const HomePage_ = ({ showError }: { showError?: boolean }) => {
  const [isShowingError, setIsShowingError] = useState(showError)

  const buttons = [
    {
      id: "search-districts-btn",
      text: "Browse Highest Rated Districts",
      href: SEARCH_DISTRICTS_PATH,
    },
    {
      id: "principals-by-rating-btn",
      text: "Browse Highest Rated Principals",
      href: PRINCIPALS_BY_RATING_PATH,
    },
    {
      id: "find-a-principal-btn",
      text: "Find a Principal",
      href: FIND_A_PRINCIPAL_PATH,
    },
    {
      id: "surprise-me-btn",
      text: "Surprise Me!",
      href: SURPRISE_ME_PATH,
    },
  ]
  return (
    <Page
      title={
        <Typography
          variant="h3"
          style={{ fontWeight: 600, fontSize: 60, textAlign: "center" }}
        >
          Rate Your Principal
        </Typography>
      }
      verticallyCentered
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {buttons.map((button, index) => (
          <Button
            key={index}
            variant="contained"
            href={button.href}
            id={button.id}
            style={{
              textAlign: "center",
              minWidth: "20vw",
              height: 180,
              fontSize: "1.3em",
              textTransform: "none",
            }}
          >
            <h3>{button.text}</h3>
          </Button>
        ))}
      </Stack>
      {isShowingError && (
        <Snackbar
          open={isShowingError}
          onClose={() => setIsShowingError(false)}
          message="Something went wrong. Please try again."
          autoHideDuration={6000}
        />
      )}
    </Page>
  )
}
