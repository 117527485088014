import { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { supabase } from "./supabase"
import { HOME_PATH } from "./constants"

export const AuthenticationWrapper = () => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (!session) {
        navigate(HOME_PATH)
      } else {
        setIsLoggedIn(true)
      }
    })
  }, [])

  if (!isLoggedIn) {
    return null
  } else {
    return <Outlet />
  }
}
