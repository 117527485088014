import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { PRINCIPALS_BY_DISTRICT_PATH } from "./constants"
import { getRandomDistrictName } from "./utils/queryUtils"

export const RandomDistrictRedirecter = () => {
  const [districtName, setDistrictName] = useState<string | null>(null)
  const navigate = useNavigate()
  useEffect(() => {
    ;(async () => {
      const name = await getRandomDistrictName()
      if (name) setDistrictName(name)
    })()
  }, [])
  if (districtName) {
    navigate(`${PRINCIPALS_BY_DISTRICT_PATH}/${districtName}`)
  }
  return null
}
