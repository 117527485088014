import React from "react"
import { Grid, Typography } from "@mui/material"
import { ratingToWord } from "./ratingMappings"

// @ts-ignore
import ReactSpeedometer from "react-d3-speedometer"
import { centeredStyle } from "./global"

interface PromptRatingWidgetProps {
  promptText: string
  rating: number
}

const PromptRatingWidget: React.FC<PromptRatingWidgetProps> = ({
  promptText,
  rating,
}) => {
  const promptTextSegments = promptText.split("{adverb}")

  return (
    <Grid container rowSpacing={{ xs: 1, md: 3 }} justifyContent={"center"}>
      <Grid item xs={12} md={6} style={centeredStyle}>
        <ReactSpeedometer
          value={rating + 0.5}
          maxValue={7}
          segments={7}
          maxSegmentLabels={0}
          currentValueText=""
          width={150}
          height={100}
          needleHeightRatio={0.5}
        />
      </Grid>
      <Grid item xs={12} md={6} style={centeredStyle}>
        <Typography variant="body1">
          {promptTextSegments[0]} <strong>{ratingToWord(rating)}</strong>
          {promptTextSegments[1]}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default PromptRatingWidget
