import _ from "lodash"
import { Principal } from "../FindPrincipalPage"
import {
  RANDOM_RATED_DISTRICT_VIEW,
  PRINCIPALS_BY_DISTRICT_PATH,
  RANDOM_READY_PRINCIPAL_VIEW,
  PRINCIPAL_PATH,
  RANDOM_PRINCIPAL_VIEW,
} from "../constants"
import { supabase } from "../supabase"

export async function getRandomDistrictName() {
  const { data: districts } = (await supabase
    .from(RANDOM_RATED_DISTRICT_VIEW)
    .select("*")) as { data: { name: string }[] }

  if (districts.length) {
    return districts[0].name
  }
}

export async function getSurpriseUrl() {
  const surpriseType = _.sample(["district", "principal"])
  if (surpriseType === "district") {
    const name = await getRandomDistrictName()
    return `${PRINCIPALS_BY_DISTRICT_PATH}/${name}`
  } else {
    const { data: principals } = (await supabase
      .from(RANDOM_READY_PRINCIPAL_VIEW)
      .select("*")) as { data: Principal[] }

    if (principals.length) {
      return `${PRINCIPAL_PATH}/${principals[0].id}`
    }
  }

  const { data: principals } = (await supabase
    .from(RANDOM_PRINCIPAL_VIEW)
    .select("*")) as { data: Principal[] }

  return `${PRINCIPAL_PATH}/${principals[0].id}`
}
