import { Fab } from "@mui/material"
import { NavigateFunction } from "react-router-dom"
import { FIND_A_PRINCIPAL_PATH, YOUR_PRINCIPAL_MODE } from "./constants"

export const FloatingButton = ({
  navigate,
}: {
  navigate: NavigateFunction
}) => {
  return (
    <FloatingButton_
      onClick={() => {
        navigate(`${FIND_A_PRINCIPAL_PATH}/${YOUR_PRINCIPAL_MODE}`)
      }}
    />
  )
}

interface FloatingButton_Props {
  onClick: () => void
}

export const FloatingButton_ = ({ onClick }: FloatingButton_Props) => {
  return (
    <Fab
      className="floating-button"
      variant="extended"
      size="large"
      color="warning"
      style={{
        height: 150,
        width: 150,
        borderRadius: "50%",
        position: "fixed",
        bottom: 20,
        right: 20,
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
      }}
      onClick={onClick}
    >
      <div style={{ marginBottom: 8 }}>Are you a teacher?</div>
      <div>Rate your principal!</div>
    </Fab>
  )
}
