import { Link } from "@mui/material"
import { useEffect, useState } from "react"
import PrincipalsTable from "./PrincipalTable"
import { supabase } from "./supabase"
import { useSessionContext } from "./App"
import { useNavigate, useParams } from "react-router-dom"
import {
  PRINCIPALS_VIEW,
  STAFF_TABLE,
  YOUR_PRINCIPAL_MODE,
  ADD_PRINCIPAL_PATH,
  PRE_REVIEW_PATH,
} from "./constants"
import { Page } from "./Page"
import { PageTitle } from "./widgets/PageTitle"

export interface Principal {
  id: number
  firstName: string
  lastName: string
  fullName: string
  school: string
  district: string
  city?: string
  state: string
  submissionCount: number
  verifiedRatersCount: number
}

export interface RatedPrincipal extends Principal {
  averageRating: number
}

export const FindPrincipalPage = () => {
  const { session } = useSessionContext()
  const { mode } = useParams()
  const [principalOfUser, setPrincipalOfUser] = useState<Principal | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession()
      if (session) {
        const {
          data: { user },
        } = await supabase.auth.getUser()
        if (user) {
          const { data: teacher } = await supabase
            .from(STAFF_TABLE)
            .select("*")
            .eq("email", user.email)
            .limit(1)
            .single()

          const { data: principals } = await supabase
            .from(PRINCIPALS_VIEW)
            .select("*")
            .eq("district", teacher?.district)
            .eq("state", teacher?.state)
            .limit(1)

          if (principals?.length) {
            setPrincipalOfUser(principalOfUser)
          }
        }
      }
    })()
  }, [])

  const handlePrincipalClick = (principalId: number) => {
    navigate(`/principal/${principalId}`)
  }

  return (
    <Page
      title={
        <PageTitle
          firstWord="Find"
          restOfTitle={
            mode === YOUR_PRINCIPAL_MODE
              ? "Your Principal to Rate Them "
              : "a Principal"
          }
        />
      }
    >
      <PrincipalsTable onRowClick={handlePrincipalClick} />
      {session && principalOfUser && (
        <nav>
          <Link href={`${PRE_REVIEW_PATH}/${principalOfUser.id}`}>
            Post a Rating for Your Principal "{principalOfUser.fullName}" at{" "}
            {principalOfUser.school}
          </Link>
        </nav>
      )}
      <Link href={ADD_PRINCIPAL_PATH} style={{ textAlign: "center" }}>
        Can't find your principal? Submit their info to have them added!
      </Link>
    </Page>
  )
}
