import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App.tsx"
import "./index.css"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { FindPrincipalPage } from "./FindPrincipalPage.tsx"
import CreateRatingPage from "./CreateRatingPage.tsx"
import { AuthenticationWrapper } from "./AuthenticationWrapper.tsx"
import PrincipalPage from "./PrincipalPage.tsx"
import {
  ADD_PRINCIPAL_PATH,
  FIND_A_PRINCIPAL_PATH,
  LOGIN_HANDLER_PATH,
  NEW_RATING_PATH,
  PASSWORD_LOGIN_PATH,
  PRE_REVIEW_PATH,
  PRINCIPAL_NOT_FOUND_PATH,
  SELECT_PRINCIPAL_PATH,
  STATS_BY_LOC_PATH,
  PRINCIPAL_PATH,
  EMAILS_PATH,
  PRINCIPALS_BY_RATING_PATH,
  PRINCIPALS_BY_DISTRICT_PATH,
  DISTRICTS_BY_RATING_PATH,
  SEARCH_DISTRICTS_PATH,
  HOME_PATH,
  DONATE_PATH,
  TOKEN_HANDLER_PATH,
  SIGN_UP_PATH,
  REPORTED_PROBLEMS_PATH,
  RANDOM_DISTRICT_REDIRECTER_PATH,
  NEW_RATING_THANK_YOU_PATH,
  SURPRISE_ME_PATH,
  NOTIFICATION_SIGN_UPS_PATH,
} from "./constants.ts"
import PreReviewPage from "./PreReviewPage.tsx"
import { SelectPrincipalPage } from "./SelectPrincipalPage.tsx"
import PrincipalNotFoundPage from "./PrincipalNotFoundPage.tsx"
import LoginHandler from "./LoginHandler.tsx"
import AddPrincipalPage from "./AddPrincipalPage.tsx"
import { PasswordLoginHandler } from "./PasswordLoginHandler.tsx"
import { EmailsPage } from "./EmailsPage.tsx"
import { StatsByLocPage } from "./StatsByLocPage.tsx"
import { PrincipalsByRatingPage } from "./PrincipalsByRatingPage.tsx"
import { PrincipalsByDistrictPage } from "./PrincipalsByDistrictPage.tsx"
import { DistrictsByRatingPage } from "./DistrictsByRatingPage.tsx"
import { DistrictSearchPage } from "./DistrictSearchPage.tsx"
import { HomePage } from "./HomePage.tsx"
import { SignUpPage } from "./SignUpPage.tsx"
import { DonatePage } from "./pages/DonatePage.tsx"
import { TokenHandler } from "./TokenHandler.tsx"
import { initTrackers, trackEvent } from "./utils/tracking.ts"
import { ReportedProblemsPage } from "./ReportedProblemsPage.tsx"
import { RandomDistrictRedirecter } from "./RandomDistrictRedirecter.tsx"
import { CreateRatingConfirmationPage } from "./CreateRatingConfirmationPage.tsx"
import { SurpriseMeHandler } from "./SurpriseMeHandler.tsx"
import { NotificationSignUpsPage } from "./NotificationSignUpsPage.tsx"

initTrackers()
trackEvent("pageview", { url: window.location.pathname })

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: HOME_PATH,
        element: <HomePage />,
      },
      {
        path: `${TOKEN_HANDLER_PATH}/:tokenId`,
        element: <TokenHandler />,
      },
      {
        path: LOGIN_HANDLER_PATH,
        element: <LoginHandler />,
      },
      {
        path: `${FIND_A_PRINCIPAL_PATH}/:mode?`,
        element: <FindPrincipalPage />,
      },
      {
        path: PRINCIPALS_BY_RATING_PATH,
        element: <PrincipalsByRatingPage />,
      },
      {
        path: SEARCH_DISTRICTS_PATH,
        element: <DistrictSearchPage />,
      },
      {
        path: `${SURPRISE_ME_PATH}/:forceFailure?`,
        element: <SurpriseMeHandler />,
      },
      {
        path: `${PRINCIPALS_BY_DISTRICT_PATH}/:district`,
        element: <PrincipalsByDistrictPage />,
      },
      {
        path: RANDOM_DISTRICT_REDIRECTER_PATH,
        element: <RandomDistrictRedirecter />,
      },
      {
        path: DISTRICTS_BY_RATING_PATH,
        element: <DistrictsByRatingPage />,
      },
      {
        path: `${STATS_BY_LOC_PATH}/:district`,
        element: <StatsByLocPage />,
      },
      {
        path: `${SIGN_UP_PATH}/:principalId`,
        element: <SignUpPage />,
      },
      {
        path: `${PRINCIPAL_PATH}/:principalId`,
        element: <PrincipalPage />,
      },
      {
        path: ADD_PRINCIPAL_PATH,
        element: <AddPrincipalPage />,
      },
      {
        path: DONATE_PATH,
        element: <DonatePage />,
      },
      {
        path: `${PASSWORD_LOGIN_PATH}/:email/:password`,
        element: <PasswordLoginHandler />,
      },
      {
        element: <AuthenticationWrapper />,
        children: [
          {
            path: `${PRE_REVIEW_PATH}/:principalId`,
            element: <PreReviewPage />,
          },
          {
            path: `${SELECT_PRINCIPAL_PATH}`,
            element: <SelectPrincipalPage />,
          },
          {
            path: PRINCIPAL_NOT_FOUND_PATH,
            element: <PrincipalNotFoundPage />,
          },
          {
            path: `${NEW_RATING_PATH}/:principalId`,
            element: <CreateRatingPage />,
          },
          {
            path: NEW_RATING_THANK_YOU_PATH,
            element: <CreateRatingConfirmationPage />,
          },
          {
            path: EMAILS_PATH,
            element: <EmailsPage />,
          },
          {
            path: REPORTED_PROBLEMS_PATH,
            element: <ReportedProblemsPage />,
          },
          {
            path: NOTIFICATION_SIGN_UPS_PATH,
            element: <NotificationSignUpsPage />,
          },
        ],
      },
    ],
  },
])

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
