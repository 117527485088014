import React from "react"
import { ReportProblemWidget } from "./ReportProblemWidget"

export const ReportPrincipalProblemWidget: React.FC = () => {
  const problemTypes = [
    {
      value: "principal_info_incorrect",
      label: "My principal is listed with the wrong school",
    },
    {
      value: "school_info_incorrect",
      label: "My school's information is incorrect",
    },
  ]
  return <ReportProblemWidget typeOptions={problemTypes} />
}
