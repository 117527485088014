import { supabase } from "../supabase"

export const insert = async <T>({
  table,
  data,
}: {
  table: string
  data: T
}): Promise<void> => {
  await supabase.from(table).insert(data).throwOnError()
}

export const makeQuery = async <T>(query: any): Promise<T> => {
  const { data } = await query.throwOnError()
  return data
}
