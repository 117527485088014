import { Button, Typography, Alert, Snackbar, Grid } from "@mui/material"
import {
  EMAIL_TRIGGERS_TABLE,
  INVITE_TO_REVIEW_TYPE,
  PRE_REVIEW_PATH,
  SIGN_UP_PATH,
} from "./constants"
import { centeredStyle } from "./global"
import { supabase } from "./supabase"
import { emailIsValid } from "./utils"
import { FormEvent, useEffect, useState } from "react"
import _ from "lodash"
import { Principal } from "./FindPrincipalPage"
import { User } from "@supabase/supabase-js"
import { TextField } from "./components/TextField"

type Email = string

interface ReviewOrInviteWidgetProps {
  principal: Principal
}

export const ReviewOrInviteWidget = ({
  principal,
}: ReviewOrInviteWidgetProps) => {
  const [user, setUser] = useState<User | null>(null)
  const [error, setError] = useState<null | string>(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    ;(async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser()
      setUser(user)
    })()
  }, [])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setError(null)

    const formData = new FormData(e.currentTarget)
    const emailString = formData.get("emails") as string
    const emails = emailString.split(",").map((email: Email) => email.trim())

    if (emails.find((email) => !emailIsValid(email))) {
      setError("Please use district emails only.")
    } else if (emails.length > 10) {
      setError("Only up to 10 emails can be invited at once.")
    } else {
      e.currentTarget.reset()
      await supabase.from(EMAIL_TRIGGERS_TABLE).insert(
        emails.map((email) => ({
          type: INVITE_TO_REVIEW_TYPE,
          email,
          inviteSenderId: user?.id,
          principalId: principal.id,
          environment: process.env.NODE_ENV,
        })),
      )

      setOpen(true)
    }
  }

  return (
    <Grid
      container
      rowSpacing={3}
      style={{
        display: "flex",
        marginTop: "20px",
        marginBottom: "40px",
        alignItems: "center",
        maxWidth: "550px",
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        style={{
          textAlign: "center",
        }}
      >
        <Typography variant="body2" align="center" gutterBottom>
          Are you a teacher at <br /> {principal.school}?
        </Typography>
        <nav>
          <Button
            id="rate-principal-btn"
            href={
              user
                ? `${PRE_REVIEW_PATH}/${principal.id}`
                : `${SIGN_UP_PATH}/${principal.id}`
            }
            variant="contained"
            style={{ width: "100%" }}
          >
            Rate {principal.fullName}
          </Button>
        </nav>
      </Grid>
      <Grid item xs={12} md={2} style={{ textAlign: "center" }}>
        OR
      </Grid>
      <Grid item xs={12} md={5} style={{ textAlign: "center" }}>
        <form
          id="invite-teacher-form"
          style={{ ...centeredStyle }}
          onSubmit={handleSubmit}
        >
          <Typography
            variant="body2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Anonymously invite teachers at {principal.school} to rate{" "}
            {principal.fullName}
          </Typography>
          <TextField
            required
            name="emails"
            label="Teachers' emails"
            style={{ width: "100%", margin: 2 }}
          />
          <Typography variant="body2" align="center" color="text.secondary">
            For multiple emails, separate with commas. Up to 10 emails can be
            invited at once.
          </Typography>
          {error && (
            <Alert severity="error" style={{ margin: 15 }}>
              {error}
            </Alert>
          )}
          <Button
            id="invite-teacher-btn"
            type="submit"
            variant="contained"
            style={{ marginTop: 10 }}
          >
            Send invite!
          </Button>
        </form>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Invite sent!
        </Alert>
      </Snackbar>
    </Grid>
  )
}
