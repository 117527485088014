import { useEffect, useState } from "react"
import { supabase } from "./supabase"
import { EMAILS_TABLE } from "./constants"

interface Email {
  id: number
  createdAt: string
  type: string
  recipientEmail: string
  subject: string
  body: string
}

export const EmailsPage = () => {
  const [emails, setEmails] = useState<Email[]>([])
  useEffect(() => {
    ;(async () => {
      const { data: emails } = (await supabase
        .from(EMAILS_TABLE)
        .select("*")
        .order("createdAt", { ascending: false })) as { data: Email[] }

      setEmails(emails)
    })()
  }, [])

  return (
    <div>
      <h1>Emails</h1>
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>createdAt</th>
            <th>type</th>
            <th>recipientEmail</th>
            <th>subject</th>
            <th>body</th>
          </tr>
        </thead>
        <tbody>
          {emails.map((email, index) => (
            <tr key={email.id} id={`email-${index}`}>
              <td>{email.id}</td>
              <td>{email.createdAt}</td>
              <td>{email.type}</td>
              <td>{email.recipientEmail}</td>
              <td>{email.subject}</td>
              <td>{email.body}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
