import { RatedPrincipal } from "./FindPrincipalPage"
import { useEffect, useState } from "react"
import { PRINCIPALS_VIEW } from "./constants"
import { supabase } from "./supabase"
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { centeredStyle } from "./global"
import { Field, Table } from "./Table"
import { TextField } from "./components/TextField"

const rowsPerPage = 10

export const principalFields: Field<RatedPrincipal>[] = [
  {
    id: "fullName",
    numeric: false,
    label: "Name",
  },
  {
    id: "school",
    numeric: false,
    label: "School",
  },
  {
    id: "city",
    numeric: false,
    label: "City",
  },
  {
    id: "state",
    numeric: false,
    label: "State",
  },
]

interface PrincipalsTableProps {
  fields?: Field<RatedPrincipal>[]
  searchEvenWhenBlank?: boolean
  searchTermFields?: (keyof RatedPrincipal)[]
  searchFieldPlaceholder?: string
  databaseTable?: string
  defaultSortField?: keyof RatedPrincipal
  queryModifier?: (query: any) => void
  onRowClick: (id: number) => void
}

type SchoolTypeOption =
  | "all"
  | "highSchool"
  | "middleSchool"
  | "elementarySchool"
  | "preschool"
  | "unknown"

interface SearchParams {
  searchTerm: string
  schoolType: SchoolTypeOption
}

export default function PrincipalsTable({
  fields = principalFields,
  searchEvenWhenBlank = false,
  searchTermFields,
  searchFieldPlaceholder,
  databaseTable = PRINCIPALS_VIEW,
  defaultSortField,
  queryModifier,
  onRowClick,
}: PrincipalsTableProps) {
  const [searchResults, setSearchResults] = useState<
    RatedPrincipal[] | undefined
  >()
  const [searchResultCount, setSearchResultCount] = useState<number>(0)
  const [searchParams, setSearchParams] = useState<SearchParams>({
    searchTerm: "",
    schoolType: "all",
  })
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchChange = (
    paramField: keyof typeof searchParams,
    value: string,
  ) => {
    setSearchParams({ ...searchParams, [paramField]: value })
    setPageIndex(0)
  }

  async function search({
    searchParams,
    onlyCount,
  }: {
    searchParams: SearchParams
    onlyCount?: boolean
  }) {
    const { searchTerm, schoolType } = searchParams
    if (searchEvenWhenBlank || searchTerm.length > 0) {
      setIsLoading(true)
      try {
        const query = supabase
          .from(databaseTable)
          .select(onlyCount ? "id" : "*")

        if (queryModifier) {
          queryModifier(query)
        }

        const sanitizedSearch = searchTerm.replace(/[^a-zA-Z0-9 ]/g, "")
        const fields = searchTermFields || ["fullName"]
        const likeConditions = fields.map(
          (field) => `${field}.ilike.%${sanitizedSearch}%`,
        )
        const likeStatement = likeConditions.join(",")
        query.or(likeStatement)

        if (schoolType !== "all") query.eq("schoolType", schoolType)

        if (onlyCount) {
          query.limit(300)
        } else {
          if (defaultSortField)
            query.order(defaultSortField, { ascending: false })

          query.range(
            pageIndex * rowsPerPage,
            (pageIndex + 1) * rowsPerPage - 1,
          )
          query.limit(rowsPerPage)
        }

        const response = await query
        return response.data
      } catch (error) {
        console.error("Error:", error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      ;(async () => {
        const results = await search({ searchParams })
        setSearchResults(results as RatedPrincipal[])
      })()
    }, 500)

    return () => clearTimeout(timeout)
  }, [searchParams, pageIndex])

  useEffect(() => {
    const timeout = setTimeout(() => {
      ;(async () => {
        const results = await search({ searchParams, onlyCount: true })
        setSearchResultCount(results?.length || 0)
      })()
    }, 500)

    return () => clearTimeout(timeout)
  }, [searchParams])

  return (
    <Box style={{ ...centeredStyle, width: "100%" }}>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <TextField
          id="search-textfield"
          placeholder={
            searchFieldPlaceholder || "Type to search by principal name"
          }
          sx={{ width: { md: 500 }, flex: { xs: 1, md: "none" } }}
          style={{ marginBottom: 30 }}
          onChange={(e) => handleSearchChange("searchTerm", e.target.value)}
        />
        <FormControl id="school-type-form-control" style={{ marginLeft: 10 }}>
          <InputLabel id="school-type-label">School type</InputLabel>
          <Select
            id="school-type-select"
            label="School type"
            defaultValue={"all"}
            onChange={(e) => handleSearchChange("schoolType", e.target.value)}
            style={{ backgroundColor: "white" }}
          >
            <MenuItem value={"all"}>All Types</MenuItem>
            <MenuItem value={"highSchool"}>High School</MenuItem>
            <MenuItem value={"middleSchool"}>Middle School</MenuItem>
            <MenuItem value={"elementarySchool"}>Elementary School</MenuItem>
            <MenuItem value={"preschool"}>Preschool</MenuItem>
            <MenuItem value={"unknown"}>Other</MenuItem>
          </Select>
        </FormControl>
      </div>

      <Table<RatedPrincipal>
        fields={fields}
        rows={searchResults}
        totalRowCount={searchResultCount}
        pageIndex={pageIndex}
        isLoading={isLoading}
        onRowClick={(row) => onRowClick(row.id)}
        onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
      />
    </Box>
  )
}
