import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { supabase } from "./supabase"
import { PRINCIPALS_VIEW } from "./constants"
import { Principal } from "./FindPrincipalPage"
import NotReadyPrincipalPage from "./NotReadyPrincipalPage"
import ReadyPrincipalPage from "./ReadyPrincipalPage"
import LoadingPage from "./LoadingPage"

const PrincipalPage: React.FC = () => {
  const { principalId } = useParams<{ principalId: string }>()
  const [principal, setPrincipal] = useState<Principal | null>(null)

  useEffect(() => {
    ;(async () => {
      const { data: principal } = await supabase
        .from(PRINCIPALS_VIEW)
        .select("*")
        .eq("id", principalId)
        .single()

      setPrincipal(principal)
    })()
  }, [])

  if (principal === null) {
    return <LoadingPage />
  } else if (principal.verifiedRatersCount < 3) {
    return (
      <NotReadyPrincipalPage
        principal={principal}
        submissionCount={principal.submissionCount}
      />
    )
  } else {
    return <ReadyPrincipalPage />
  }
}

export default PrincipalPage
