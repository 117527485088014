import { Button } from "@mui/material"
import { centeredStyle } from "./global"
import { GREEN, NAVY } from "./constants"
import { CSSProperties } from "react"
import "./assets/brandonRegular.css"

export const Footer = () => {
  const socialMediaLinks = [
    {
      link: "https://www.facebook.com/teachergimbel",
      fileName: "/facebookIcon.png",
    },
    {
      link: "https://www.instagram.com/teachergimbelcorp",
      fileName: "/instagramIcon.png",
    },
    {
      link: "https://www.youtube.com/@TeacherGimbel",
      fileName: "/youtubeIcon.png",
    },
    {
      link: "https://twitter.com/teachergimbel",
      fileName: "/xIcon.png",
    },
  ]
  return (
    <div style={styles.container}>
      <img src="/logo.png" style={styles.logo} />
      <Button
        href="mailto:ryp@teachergimbelcorporation.org"
        variant="contained"
        color="success"
        style={styles.button}
      >
        Contact Us
      </Button>
      <Button
        href="https://www.teachergimbelcorporation.org/"
        variant="contained"
        style={styles.button}
      >
        Learn More About TeacherGimbel Corporation
      </Button>
      <div style={{ display: "flex" }}>
        {socialMediaLinks.map((link, index) => (
          <a href={link.link} style={styles.socialMediaLink} key={index}>
            <img src={link.fileName} width={39} />
          </a>
        ))}
      </div>
      <div style={styles.copyright}>
        ©2025 TeacherGimbel Corporation. All rights reserved.
      </div>
    </div>
  )
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    paddingTop: 40,
    WebkitFontSmoothing: "antialiased",
    width: "100%",
    backgroundColor: NAVY,
    ...centeredStyle,
  },
  logo: {
    width: 324,
    height: 162,
    objectFit: "cover",
    objectPosition: "50% 50%",
  },
  button: {
    borderRadius: 50,
    fontFamily: "BrandonRegular",
    fontSize: 16,
    marginBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: GREEN,
    textAlign: "center",
  },
  socialMediaLink: {
    marginLeft: 5,
    marginRight: 5,
  },
  copyright: {
    fontFamily: "BrandonRegular",
    fontSize: 16,
    color: "white",
    marginTop: 40,
    marginBottom: 20,
  },
}
