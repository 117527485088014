export const USER_SETTING_SETS_TABLE = "UserSettingSets"
export const STAFF_TABLE = "Staff"
export const USER_PRINCIPAL_RELATIONS_TABLE = "UserPrincipalRelations"
export const PRINCIPALS_VIEW = "Principals"
export const READY_PRINCIPALS_VIEW = "ReadyPrincipals"
export const ADDED_PRINCIPALS_TABLE = "AddedPrincipals"
export const RANDOM_PRINCIPAL_VIEW = "RandomPrincipal"
export const RANDOM_READY_PRINCIPAL_VIEW = "RandomReadyPrincipal"
export const DISTRICTS_VIEW = "Districts"
export const RATED_DISTRICTS_VIEW = "RatedDistricts"
export const RANDOM_RATED_DISTRICT_VIEW = "RandomRatedDistrict"
export const EMAIL_TRIGGERS_TABLE = "EmailTriggers"
export const EMAILS_TABLE = "Emails"
export const SURVEY_SUBMISSIONS_TABLE = "SurveySubmissions"
export const SURVEY_SECTIONS_TABLE = "SurveySections"
export const SURVEY_PROMPTS_TABLE = "SurveyPrompts"
export const PROMPT_SCORES_TABLE = "PromptScores"
export const COMMENTS_TABLE = "Comments"
export const ANECDOTE_VOTES_TABLE = "AnecdoteVotes"
export const PUBLIC_PROMPT_SCORES_TABLE = "AveragePromptScores"
export const PUBLIC_COMMENTS_TABLE = "CommentsWithEnoughSubmissions"
export const REPORTED_PROBLEMS_TABLE = "ReportedProblems"
export const STATIC_PAGES_TABLE = "StaticPages"
export const SIGN_IN_TOKENS_TABLE = "SignInTokens"
export const NOTIFICATION_SIGN_UPS_TABLE = "NotificationSignUps"

export const HOME_PATH = "/"
export const FIND_A_PRINCIPAL_PATH = "/find-a-principal"
export const YOUR_PRINCIPAL_MODE = "yourPrincipal"
export const PRINCIPALS_BY_RATING_PATH = "/principals-by-rating"
export const SEARCH_DISTRICTS_PATH = "/search-districts"
export const PRINCIPALS_BY_DISTRICT_PATH = "/principals-by-district"
export const SURPRISE_ME_PATH = "/surprise-me"
export const RANDOM_DISTRICT_REDIRECTER_PATH = "/random-district-redirecter"
export const DISTRICTS_BY_RATING_PATH = "/districts-by-rating"
export const SIGN_UP_PATH = "/sign-up"
export const TOKEN_HANDLER_PATH = "/token-handler"
export const LOGIN_HANDLER_PATH = "/login-handler"
export const PRE_REVIEW_PATH = "/pre-review"
export const SELECT_PRINCIPAL_PATH = "/select-principal"
export const PRINCIPAL_NOT_FOUND_PATH = "/principal-not-found"
export const NEW_RATING_PATH = "/new-rating"
export const NEW_RATING_THANK_YOU_PATH = "/new-rating-thank-you"
export const ADD_PRINCIPAL_PATH = "/add-your-principal"
export const PRINCIPAL_PATH = "/principal"
export const EMAILS_PATH = "/emails"
export const REPORTED_PROBLEMS_PATH = "/reported-problems"
export const NOTIFICATION_SIGN_UPS_PATH = "/notification-sign-ups"
export const PASSWORD_LOGIN_PATH = "/password-login"
export const STATS_BY_LOC_PATH = "/area-results"
export const DONATE_PATH = "/donate"

export const STATIC_PATH = "https://www.rateyourprincipal.org"
export const ABOUT_US_PATH = `${STATIC_PATH}/about-us`
export const CONTACT_US_PATH = `${STATIC_PATH}/contact-us`
export const FAQS_PATH = `${STATIC_PATH}/faqs`
export const PRIVACY_POLICY_PATH = `${STATIC_PATH}/privacy-policy`
export const PRINCIPAL_RESOURCES_PATH = `${STATIC_PATH}/resources-for-principals`

export const SIGN_UP_TYPE = "SIGN_UP"
export const INVITE_TO_REVIEW_TYPE = "INVITE_TO_REVIEW"
export const GET_NEW_SIGN_IN_LINK_TYPE = "GET_NEW_SIGN_IN_LINK"
export const VOTE_ON_ANECDOTE_TYPE = "VOTE_ON_ANECDOTE"
export const SIGN_UP_FOR_STATE_READY_NOTIFICATION_TYPE =
  "SIGN_UP_FOR_STATE_READY_NOTIFICATION"

export const STATE_READY_NOTIF = "STATE_READY"

export const NON_EXISTENT_USER_ID = "-1"

export const NAVY = "#222759"
export const GREEN = "#008D4F"
