import { Flag, ThumbDown, ThumbUp } from "@mui/icons-material"
import { Typography, IconButton } from "@mui/material"
import React, { useState } from "react"
import { ReportProblemWidget } from "./ReportProblemWidget"

export interface Anecdote {
  id: number
  principalId: number
  sectionId: number
  text: string
}

export type VoteType = "up" | "down"

export interface AnecdoteVote {
  id: number
  anecdoteId: number
  userId: string
  type: VoteType
}

export interface AnecdoteWidgetProps {
  anecdote: Anecdote
  votes: AnecdoteVote[]
  userId: string | null
  onVote: ({
    anecdoteId,
    type,
  }: {
    anecdoteId: number
    type: VoteType
  }) => Promise<void>
  onUndoVote: ({ anecdoteId }: { anecdoteId: number }) => Promise<void>
}

export const AnecdotesWidget: React.FC<AnecdoteWidgetProps> = ({
  anecdote,
  votes,
  userId,
  onVote,
  onUndoVote,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isShowingFlagForm, setIsShowingFlagForm] = useState(false)

  const handleVote = async (type: VoteType) => {
    setIsLoading(true)
    await onVote({ anecdoteId: anecdote.id, type })
    setIsLoading(false)
  }

  const handleUndoVote = async () => {
    setIsLoading(true)
    await onUndoVote({ anecdoteId: anecdote.id })
    setIsLoading(false)
  }

  const userVote = votes.find((v) => v.userId === userId)?.type
  const upvoteCount = votes.filter((v) => v.type === "up").length
  const downvoteCount = votes.filter((v) => v.type === "down").length

  const voteStyle = { marginRight: 5 }

  const renderVoteButton = (type: VoteType) => {
    return (
      <IconButton
        className={`vote-${type}`}
        onClick={() =>
          userVote === type ? handleUndoVote() : handleVote(type)
        }
        color={userVote === type ? "primary" : "inherit"}
        disabled={isLoading}
        title={userVote === type ? "You voted this" : "Click to vote"}
        style={{ margin: "0px 3px" }}
      >
        {type === "up" ? (
          <ThumbUp style={voteStyle} />
        ) : (
          <ThumbDown style={voteStyle} />
        )}
        <Typography variant="body2">
          {type === "up" && upvoteCount}
          {type === "down" && downvoteCount}
        </Typography>
      </IconButton>
    )
  }

  const problemTypes = [
    {
      value: "inappropriate",
      label: "Inappropriate content",
    },
    {
      value: "incorrect",
      label: "Incorrect information",
    },
  ]

  return (
    <div style={{ margin: "20px 0px" }} className="anecdotes-widget">
      <Typography variant="body1">{anecdote.text}</Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        {renderVoteButton("up")}
        {renderVoteButton("down")}
        <IconButton
          className="report-button"
          color="inherit"
          onClick={() => setIsShowingFlagForm(!isShowingFlagForm)}
          disabled={isLoading}
          style={{ margin: "0px 3px" }}
        >
          <Flag style={{ marginRight: 5 }} />
          <Typography variant="body2">
            {!isShowingFlagForm ? "Report this" : "Cancel report"}
          </Typography>
        </IconButton>
      </div>
      {isShowingFlagForm && (
        <div style={{ display: "flex" }}>
          <ReportProblemWidget
            labelWhenNoSelection="Select a problem"
            typeOptions={problemTypes}
            showUserFields={false}
          />
        </div>
      )}
    </div>
  )
}
