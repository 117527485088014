import { ADDED_PRINCIPALS_TABLE } from "./constants"
import { Page } from "./Page"
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextFieldProps,
} from "@mui/material"
import { Principal } from "./FindPrincipalPage"
import { centeredStyle } from "./global"
import { CSSProperties, useState } from "react"
import { insert } from "./utils/dbUtils"
import { TextField } from "./components/TextField"

export interface PrincipalSuggestion
  extends Omit<
    Principal,
    | "id"
    | "fullName"
    | "averageRating"
    | "submissionCount"
    | "verifiedRatersCount"
  > {
  submitterEmail: string
}

const AddPrincipalPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  return (
    <_AddPrincipalPage
      isSubmitted={isSubmitted}
      onSubmit={async (principal) => {
        await insert<PrincipalSuggestion>({
          table: ADDED_PRINCIPALS_TABLE,
          data: principal,
        })
        setIsSubmitted(true)
      }}
    />
  )
}

export default AddPrincipalPage

interface _AddPrincipalPageProps {
  isSubmitted: boolean
  onSubmit: (principal: PrincipalSuggestion) => Promise<void>
}

export const _AddPrincipalPage = ({
  isSubmitted,
  onSubmit,
}: _AddPrincipalPageProps) => {
  const [position, setPosition] = useState("")

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const firstName = formData.get("firstName") as string
    const lastName = formData.get("lastName") as string
    const school = formData.get("school") as string
    const district = formData.get("district") as string
    const state = formData.get("state") as string
    const email = formData.get("email") as string
    await onSubmit({
      firstName,
      lastName,
      school,
      district,
      state,
      submitterEmail: email,
    })
  }

  const formElementProps: TextFieldProps = {
    style: { margin: 5, width: 300 } as CSSProperties,
    required: true,
  }

  const textFields = [
    { label: "First Name", name: "firstName", id: "textfield-firstName" },
    { label: "Last Name", name: "lastName", id: "textfield-lastName" },
    { label: "School", name: "school", id: "textfield-school" },
    { label: "District", name: "district", id: "textfield-district" },
    { label: "State", name: "state", id: "textfield-state" },
    {
      label: "Your Email",
      name: "email",
      id: "textfield-email",
      required: false,
    },
  ]

  if (isSubmitted) {
    return (
      <Page
        title="Thank you for submitting your principal!"
        subtitle="We will review the information you submitted and add them to our database upon successful verification."
      />
    )
  }

  return (
    <Page title="Add Your Principal">
      <form onSubmit={handleSubmit} style={{ ...centeredStyle, margin: 5 }}>
        {textFields.map((props, i) => (
          <TextField key={i} {...formElementProps} {...props} />
        ))}
        <FormControl
          id="position-form-control"
          style={formElementProps.style}
          required
        >
          <InputLabel id="position-label">Position</InputLabel>
          <Select
            id="position-select"
            label="Position"
            value={position}
            style={{ backgroundColor: "white" }}
            onChange={(e) => setPosition(e.target.value as string)}
          >
            <MenuItem value="principal">Principal</MenuItem>
            <MenuItem value="assistantPrincipal">Assistant Principal</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <br />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={position !== "principal" && position !== ""}
        >
          Submit principal information
        </Button>

        {position !== "principal" && position !== "" && (
          <Alert severity="info" style={{ marginTop: 10 }}>
            We're sorry but Rate Your Principals is only for principals right
            now. We hope to expand to assistant principals and other roles in
            the coming years!
          </Alert>
        )}
      </form>
    </Page>
  )
}
