import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
} from "@mui/material"
import {
  NEW_RATING_PATH,
  PRINCIPALS_VIEW,
  PRIVACY_POLICY_PATH,
  SELECT_PRINCIPAL_PATH,
} from "./constants"
import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { Principal } from "./FindPrincipalPage"
import { supabase } from "./supabase"
import { Page } from "./Page"
import { centeredStyle } from "./global"
import LoadingPage from "./LoadingPage"

const PreReviewPage: React.FC = ({}) => {
  const { principalId } = useParams<{ principalId: string }>()
  const [principal, setPrincipal] = useState<Principal | null>(null)

  useEffect(() => {
    ;(async () => {
      const { data: principal } = await supabase
        .from(PRINCIPALS_VIEW)
        .select("*")
        .eq("id", principalId)
        .single()

      setPrincipal(principal)
    })()
  }, [])

  if (principal === null) {
    return <LoadingPage />
  }

  return <PreReviewPage_ principal={principal} />
}

interface PreReviewPage_Props {
  principal: Principal
}

export const PreReviewPage_ = ({ principal }: PreReviewPage_Props) => {
  const [hasReadPolicy, setHasReadPolicy] = useState(false)

  return (
    <Page
      title={`Ready to review ${principal.fullName} at ${principal.school}?`}
    >
      <nav style={{ ...centeredStyle }}>
        <FormGroup style={{ marginBottom: 30 }}>
          <FormControlLabel
            control={
              <Checkbox
                id="policy-checkbox"
                onChange={() => setHasReadPolicy(!hasReadPolicy)}
              />
            }
            label={
              <p>
                I've read and understand RateYourPrincipal's privacy policy{" "}
                <a href={PRIVACY_POLICY_PATH}>here</a>
              </p>
            }
          />
        </FormGroup>
        <Button
          id="begin-rating-btn"
          variant="contained"
          href={`${NEW_RATING_PATH}/${principal.id}`}
          disabled={!hasReadPolicy}
          size="large"
        >
          Let's go!
        </Button>
        <Link style={{ margin: 20 }} href={SELECT_PRINCIPAL_PATH}>
          Not your principal?
        </Link>
      </nav>
    </Page>
  )
}

export default PreReviewPage
