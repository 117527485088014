import { levelsOfAgreement } from "./SurveySection"

export const ratingToGrade = (rating: number): string => {
  if (rating === null) return "N/A"

  if (rating < 2) return "F"

  const grades = [
    "D-",
    "D",
    "D+",
    "C-",
    "C",
    "C+",
    "B-",
    "B",
    "B+",
    "A-",
    "A",
    "A+",
  ]
  const coefficient = (grades.length - 1) / (levelsOfAgreement.length - 2 - 1)
  const gradeIndex = Math.floor((rating - 2) * coefficient)
  return grades[gradeIndex]
}

export const ratingToWord = (rating: number): string => {
  const words = [
    "never",
    "rarely",
    "occasionally",
    "sometimes",
    "often",
    "usually",
    "always",
  ]
  const coefficient = (words.length - 1) / (levelsOfAgreement.length - 1)
  const wordIndex = Math.floor(rating * coefficient)
  return words[wordIndex]
}
