import { useEffect } from "react"
import LoadingPage from "./LoadingPage"
import { getSurpriseUrl } from "./utils/queryUtils"
import { useNavigate, useParams } from "react-router-dom"

export const SurpriseMeHandler = () => {
  const { forceFailure } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      try {
        if (forceFailure) {
          throw new Error("Forced failure")
        }
        const surpriseUrl = await getSurpriseUrl()
        navigate(surpriseUrl)
      } catch (e) {
        console.error(e)
        navigate("/")
      }
    })()
  }, [])

  return <LoadingPage />
}
