import { Page } from "./Page"
import { Section } from "./CreateRatingPage"
import { Typography } from "@mui/material"
import { ratingToGrade } from "./ratingMappings"
import _ from "lodash"
import { AveragePromptScore } from "./ReadyPrincipalPage"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import {
  PUBLIC_PROMPT_SCORES_TABLE,
  READY_PRINCIPALS_VIEW,
  SURVEY_SECTIONS_TABLE,
} from "./constants"
import { supabase } from "./supabase"
import { RatedPrincipal } from "./FindPrincipalPage"

export const StatsByLocPage = () => {
  const { district } = useParams() as { district: string }
  const [sections, setSections] = useState<Section[]>([])
  const [averageScores, setAverageScores] = useState<AveragePromptScore[]>([])

  useEffect(() => {
    ;(async () => {
      const { data: sections } = await supabase
        .from(SURVEY_SECTIONS_TABLE)
        .select("*")
      setSections(sections as Section[])

      const { data: principals } = (await supabase
        .from(READY_PRINCIPALS_VIEW)
        .select("*")
        .eq("district", district)) as { data: RatedPrincipal[] }

      const { data: averageScores } = await supabase
        .from(PUBLIC_PROMPT_SCORES_TABLE)
        .select("*")
        .in(
          "principalId",
          principals.map((p) => p.id),
        )

      setAverageScores(averageScores as AveragePromptScore[])
    })()
  }, [district])

  return (
    <_StatsByLocPage
      district={district}
      sections={sections}
      averageScores={averageScores}
    />
  )
}

export interface _StatsByLocPageProps {
  district: string
  sections: Section[]
  averageScores: AveragePromptScore[]
}

export const _StatsByLocPage = ({
  district,
  sections,
  averageScores,
}: _StatsByLocPageProps) => {
  const renderSection = (section: Section) => {
    const relevantScores = averageScores.filter(
      (s) => s.sectionId === section.id,
    )

    const averageRating = _.meanBy(relevantScores, "value")

    return (
      <Typography
        key={section.id}
        className="sectionGradeHeader"
        align="center"
        variant="h3"
        style={{ margin: 30 }}
      >
        {section.title} - {ratingToGrade(averageRating)}
      </Typography>
    )
  }

  return (
    <Page title={`Average Ratings for Principals in ${district}`}>
      {sections.map((section) => renderSection(section))}
    </Page>
  )
}
