import { Page } from "./Page"
import { useEffect, useState } from "react"
import { supabase } from "./supabase"
import { PRINCIPALS_BY_DISTRICT_PATH, RATED_DISTRICTS_VIEW } from "./constants"
import { ratingToGrade } from "./ratingMappings"
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { PageTitle } from "./widgets/PageTitle"

export interface RatedDistrict {
  name: string
  state: string
  averageRating: number
}

interface Field {
  field: keyof RatedDistrict
  headerName: string
  width: number
  type?: "number"
  valueFormatter?: (params: { value: any }) => string
}

export const DistrictsByRatingPage = () => {
  const navigate = useNavigate()
  const [districts, setDistricts] = useState<RatedDistrict[]>([])

  useEffect(() => {
    ;(async () => {
      const { data: districts } = (await supabase
        .from(RATED_DISTRICTS_VIEW)
        .select("*")) as { data: RatedDistrict[] }
      setDistricts(districts)
    })()
  }, [])

  const columns: Field[] = [
    { field: "name", headerName: "District", width: 200 },
    { field: "state", headerName: "State", width: 200 },
    {
      field: "averageRating",
      headerName: "Rating",
      width: 200,
      type: "number",
      valueFormatter: ({ value }: { value: number }) => ratingToGrade(value),
    },
  ]

  return (
    <Page
      title={
        <PageTitle
          firstWord="Browse"
          restOfTitle="Districts With Highest Rated Principals"
        />
      }
    >
      {districts.length > 0 && (
        <Box sx={{ width: { xs: "100%", md: 750 } }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size={"medium"}>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.field}>
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {districts.map((district) => (
                    <TableRow
                      className="district-row"
                      key={district.name}
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `${PRINCIPALS_BY_DISTRICT_PATH}/${district.name}`,
                        )
                      }
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={column.field}
                          className={`district-cell-${column.field}`}
                        >
                          {column.valueFormatter
                            ? column.valueFormatter({
                                value: district[column.field],
                              })
                            : district[column.field]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      )}
      <p>Districts without enough rated principals are not shown.</p>
    </Page>
  )
}
