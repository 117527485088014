export function emailIsValid(email: string): boolean {
  try {
    const emailRegex = /\S+@\S+\.\S+/
    if (!emailRegex.test(email)) return false

    const commonEmailProviders = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "msn.com",
      "aol.com",
      "live.com",
      "outlook.com",
      "icloud.com",
    ]
    const emailDomain = email.split("@")[1]
    return !commonEmailProviders.includes(emailDomain)
  } catch (error) {
    return false
  }
}
