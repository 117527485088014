import { CSSProperties } from "react"

export const centeredStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}

export const centeredMobileStyle = {
  display: {
    xs: "flex",
    md: "block",
  },
  flexDirection: {
    xs: "column",
  },
  justifyContent: {
    xs: "center",
  },
  alignItems: {
    xs: "center",
  },
}
