import { useNavigate } from "react-router-dom"
import { Page } from "./Page"
import PrincipalsTable, { principalFields } from "./PrincipalTable"
import { PRINCIPAL_PATH, READY_PRINCIPALS_VIEW } from "./constants"
import { ratingToGrade } from "./ratingMappings"
import { PRINCIPALS_NOT_SHOWN } from "./stringConstants"
import { Field } from "./Table"
import { RatedPrincipal } from "./FindPrincipalPage"
import { PageTitle } from "./widgets/PageTitle"

export const averageRatingField: Field<RatedPrincipal> = {
  id: "averageRating",
  label: "Average Rating",
  numeric: true,
  mapper: (rating: number) => ratingToGrade(rating),
}

export const PrincipalsByRatingPage = () => {
  const navigate = useNavigate()

  return (
    <Page
      title={
        <PageTitle firstWord="Browse" restOfTitle="Highest Rated Principals" />
      }
    >
      <PrincipalsTable
        fields={[...principalFields, averageRatingField]}
        searchEvenWhenBlank={true}
        searchTermFields={["school", "district", "state"]}
        searchFieldPlaceholder="Search by school, district, or state"
        databaseTable={READY_PRINCIPALS_VIEW}
        defaultSortField="averageRating"
        queryModifier={(query) => query.not("averageRating", "is", null)}
        onRowClick={(id) => navigate(`${PRINCIPAL_PATH}/${id}`)}
      />
      <p>{PRINCIPALS_NOT_SHOWN}</p>
    </Page>
  )
}
