import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { supabase } from "./supabase"
import { HOME_PATH } from "./constants"

export const PasswordLoginHandler: React.FC = () => {
  const { email, password } = useParams<{
    email: string
    password: string
  }>()
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      if (email && password) {
        await supabase.auth.signInWithPassword({ email, password })
        navigate(HOME_PATH)
      }
    })()
  }, [email, password])

  return <></>
}
