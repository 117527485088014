import { useEffect, useMemo, useState } from "react"
import { Section } from "./CreateRatingPage"
import {
  PUBLIC_PROMPT_SCORES_TABLE,
  PUBLIC_COMMENTS_TABLE,
  SURVEY_SECTIONS_TABLE,
  ANECDOTE_VOTES_TABLE,
  NON_EXISTENT_USER_ID,
  SIGN_UP_PATH,
  READY_PRINCIPALS_VIEW,
} from "./constants"
import { supabase } from "./supabase"
import _ from "lodash"
import { useNavigate, useParams } from "react-router-dom"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material"
import { RatedPrincipal } from "./FindPrincipalPage"
import { ratingToGrade } from "./ratingMappings"
import { Expand } from "@mui/icons-material"
import { AverageRatingSection } from "./AverageRatingSection"
import {
  Anecdote,
  AnecdoteVote,
  AnecdoteWidgetProps,
  AnecdotesWidget,
  VoteType,
} from "./AnecdotesWidget"
import { ReviewOrInviteWidget } from "./ReviewOrInviteWidget"
import { ReportPrincipalProblemWidget } from "./ReportPrincipalProblemWidget"
import LoadingPage from "./LoadingPage"
import { Page } from "./Page"

export interface AveragePromptScore {
  principalId: number
  value: number
  promptId: number
  sectionId: number
  promptText: string
}

const ReadyPrincipalPage: React.FC = () => {
  const { principalId } = useParams<{ principalId: string }>()
  const [principal, setPrincipal] = useState<RatedPrincipal | null>(null)
  const [averageScores, setAverageScores] = useState<AveragePromptScore[]>([])
  const [anecdotes, setAnecdotes] = useState<Anecdote[]>([])
  const [anecdoteVotes, setAnecdoteVotes] = useState<AnecdoteVote[]>([])
  const [userId, setUserId] = useState<string | null>(null)
  const [sections, setSections] = useState<Section[]>([])
  const navigate = useNavigate()

  async function getAnecdoteVotes({ anecdotes }: { anecdotes: Anecdote[] }) {
    const { data: anecdoteVotes } = (await supabase
      .from(ANECDOTE_VOTES_TABLE)
      .select("*")
      .in(
        "anecdoteId",
        anecdotes.map((c) => c.id),
      )) as { data: AnecdoteVote[] }

    setAnecdoteVotes(anecdoteVotes)
  }

  useEffect(() => {
    ;(async () => {
      const { data: principal } = (await supabase
        .from(READY_PRINCIPALS_VIEW)
        .select("*")
        .eq("id", principalId)
        .single()) as { data: RatedPrincipal }

      setPrincipal(principal)

      const { data: averageScores } = await supabase
        .from(PUBLIC_PROMPT_SCORES_TABLE)
        .select("*")
        .eq("principalId", principalId)

      setAverageScores(averageScores as AveragePromptScore[])

      const { data } = (await supabase
        .from(PUBLIC_COMMENTS_TABLE)
        .select("*")
        .eq("principalId", principalId)) as { data: Anecdote[] | null }

      const anecdotes = data || []

      setAnecdotes(anecdotes)

      await getAnecdoteVotes({ anecdotes })

      const {
        data: { user },
      } = await supabase.auth.getUser()

      setUserId(user?.id || NON_EXISTENT_USER_ID)

      const { data: sections } = await supabase
        .from(SURVEY_SECTIONS_TABLE)
        .select("*")
        .order("id")

      setSections(sections as Section[])
    })()
  }, [])

  const handleVote = async ({
    anecdoteId,
    type,
  }: {
    anecdoteId: number
    type: VoteType
  }) => {
    if (userId === NON_EXISTENT_USER_ID) {
      navigate(`${SIGN_UP_PATH}/${principalId}`)
      return
    }
    await supabase
      .from(ANECDOTE_VOTES_TABLE)
      .upsert({ anecdoteId, userId, type }, { onConflict: "anecdoteId,userId" })

    await getAnecdoteVotes({ anecdotes })
  }

  const handleUndoVote = async ({ anecdoteId }: { anecdoteId: number }) => {
    await supabase
      .from(ANECDOTE_VOTES_TABLE)
      .delete()
      .eq("anecdoteId", anecdoteId)
      .eq("userId", userId)

    await getAnecdoteVotes({ anecdotes })
  }

  if (principal === null || userId === null) {
    return <LoadingPage />
  }

  return (
    <_ReadyPrincipalPage
      principal={principal}
      averageScores={averageScores}
      anecdotes={anecdotes}
      anecdoteVotes={anecdoteVotes}
      userId={userId}
      sections={sections}
      onVote={handleVote}
      onUndoVote={handleUndoVote}
    />
  )
}

export interface _ReadyPrincipalPageProps
  extends Pick<AnecdoteWidgetProps, "onVote" | "onUndoVote"> {
  principal: RatedPrincipal
  averageScores: AveragePromptScore[]
  anecdotes: Anecdote[]
  anecdoteVotes: AnecdoteVote[]
  userId: string | null
  sections: Section[]
}

export const _ReadyPrincipalPage: React.FC<_ReadyPrincipalPageProps> = ({
  principal,
  averageScores,
  anecdotes,
  anecdoteVotes,
  userId,
  sections,
  onVote,
  onUndoVote,
}) => {
  const renderSection = (section: Section) => {
    const relevantScores = averageScores.filter(
      (s) => s.sectionId === section.id,
    )

    const averageRating = _.meanBy(relevantScores, "value")

    return (
      <Accordion key={section.id}>
        <AccordionSummary
          expandIcon={<Expand style={{ marginLeft: 5 }} />}
          sx={{
            "& .MuiAccordionSummary-content": { justifyContent: "center" },
          }}
        >
          <Typography align="center" variant="h5">
            {section.title}
            <br />
            <span className="letter-grade">{ratingToGrade(averageRating)}</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AverageRatingSection averagePromptScores={relevantScores} />
        </AccordionDetails>
      </Accordion>
    )
  }

  const shuffledAnecdotes = useMemo(() => {
    let seed = principal.id
    function generateRandomSeededNumber() {
      var x = Math.sin(seed++) * 10000
      return x - Math.floor(x)
    }

    return _.sortBy(anecdotes, () => generateRandomSeededNumber())
  }, [anecdotes])

  return (
    <Page
      title={`Principal ${principal.fullName} at ${principal.school} Received a`}
      id="ready-principal-page"
    >
      <Typography variant="h2" align="center" style={{ marginBottom: 50 }}>
        <span className="letter-grade">
          {ratingToGrade(principal.averageRating)}
        </span>
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            style={{ marginBottom: 50, textAlign: "center" }}
          >
            Principal {principal.lastName} received the following ratings:
          </Typography>
          {sections.map(renderSection)}
        </Grid>
        <Grid item xs={12} md={6}>
          {shuffledAnecdotes.length ? (
            <div>
              <Typography variant="h4">Anecdotes</Typography>
              {userId === NON_EXISTENT_USER_ID && (
                <Typography variant="body1">
                  Only verified teachers can upvote and downvote anecdotes.
                </Typography>
              )}
              {shuffledAnecdotes.map((a) => (
                <AnecdotesWidget
                  key={a.id}
                  anecdote={a}
                  votes={anecdoteVotes.filter((v) => v.anecdoteId === a.id)}
                  userId={userId}
                  onVote={onVote}
                  onUndoVote={onUndoVote}
                />
              ))}
            </div>
          ) : (
            <Typography variant="body1">
              We need a few more anecdotes before we can show anecdotes.
            </Typography>
          )}
        </Grid>
      </Grid>
      <ReviewOrInviteWidget principal={principal} />
      <ReportPrincipalProblemWidget />
    </Page>
  )
}

export default ReadyPrincipalPage
