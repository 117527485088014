import { useEffect, useState } from "react"
import { supabase } from "./supabase"
import { Auth } from "@supabase/auth-ui-react"
import { ThemeSupa } from "@supabase/auth-ui-shared"
import { Session } from "@supabase/supabase-js"
import { Outlet, useNavigate, useOutletContext } from "react-router-dom"
import { identifyUser, trackEvent } from "./utils/tracking"
import { AppWrapper } from "./AppWrapper"

type ContextType = { session: Session | null }

function App() {
  const [session, setSession] = useState<Session | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    identifyUser(session?.user?.email)
    trackEvent("App Page Load", {
      url: window.location.pathname,
    })
  }, [session])

  if (false) {
    return <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />
  } else {
    return (
      <AppWrapper session={session} navigate={navigate}>
        <Outlet context={{ session } satisfies ContextType} />
      </AppWrapper>
    )
  }
}

export function useSessionContext() {
  return useOutletContext<ContextType>()
}

export default App
