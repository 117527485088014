import { useEffect, useState } from "react"
import {
  EMAIL_TRIGGERS_TABLE,
  PRINCIPALS_VIEW,
  SIGN_UP_TYPE,
  USER_SETTING_SETS_TABLE,
} from "./constants"
import { supabase } from "./supabase"
import { useParams } from "react-router-dom"
import { Principal } from "./FindPrincipalPage"
import { NewSignUp, SignUpPage_ } from "./SignUpPage_"
import { insert } from "./utils/dbUtils"
import { EmailTrigger } from "./LoginHandler"

const ENV = process.env.NODE_ENV || "development"

type NewEmailTrigger = Omit<EmailTrigger, "id">

interface NewSignUpEmailTrigger extends NewEmailTrigger {
  principalId: number
}

export const SignUpPage = () => {
  const { principalId } = useParams()
  const [principal, setPrincipal] = useState<Principal | null>(null)

  useEffect(() => {
    ;(async () => {
      const { data: principal } = (await supabase
        .from(PRINCIPALS_VIEW)
        .select()
        .eq("id", principalId)
        .single()) as { data: Principal }
      setPrincipal(principal)
    })()
  }, [principalId])

  async function handleSubmit(newSignUp: NewSignUp) {
    if (!principal) {
      alert("Oops! We couldn't find the principal you selected.")
      return
    }

    await supabase.from(USER_SETTING_SETS_TABLE).insert(newSignUp)

    await insert<NewSignUpEmailTrigger>({
      table: EMAIL_TRIGGERS_TABLE,
      data: {
        email: newSignUp.email,
        type: SIGN_UP_TYPE,
        principalId: principal.id,
        environment: ENV,
      },
    })
  }

  return (
    <SignUpPage_
      title="Sign Up to Rate Your Principal!"
      submittedTitle="Thank you for signing up!"
      submittedText="We've sent a unique link to your email that you can use to rate your principal. Please be sure to check your spam folder if you do not receive it."
      school={principal?.school || ""}
      submitText="Sign Up"
      handleSubmit={handleSubmit}
    />
  )
}
