import mixpanel from "mixpanel-browser"
import ReactGA from "react-ga4"
import * as Sentry from "@sentry/react"

const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN
const isProduction = process.env.NODE_ENV === "production"

const mixpanelIsEnabled = mixpanelToken && isProduction

export function initTrackers() {
  if (isProduction) {
    if (mixpanelToken) mixpanel.init(mixpanelToken)
    else console.error("No mixpanel token found")

    Sentry.init({
      dsn: "https://84e34373f0565999b5fe9f1a59b395f9@o4506888915648512.ingest.us.sentry.io/4506888922791936",
      integrations: [Sentry.replayIntegration()],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
    })

    ReactGA.initialize("G-TCJH0NTRHV")
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  } else {
    console.log("Tracking is disabled in non-production environments.")
  }
}

export function identifyUser(email?: string) {
  if (mixpanelIsEnabled) mixpanel.identify(email)
}

export function trackEvent(eventName: string, properties: Record<string, any>) {
  if (mixpanelIsEnabled) mixpanel.track(eventName, properties)
}
