import Box from "@mui/material/Box"
import MuiTable from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import MuiTableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Paper from "@mui/material/Paper"
import { CircularProgress } from "@mui/material"

export interface Field<T> {
  id: keyof T
  label: string
  numeric: boolean
  mapper?: (value: any) => any
}

export interface TableProps<T> {
  fields: Field<T>[]
  rows?: T[]
  totalRowCount: number
  pageIndex: number
  rowsPerPage?: number
  isLoading?: boolean
  onRowClick: (row: T) => void
  onPageChange: (newPage: number) => void
}

export function Table<T>({
  fields,
  rows,
  totalRowCount,
  pageIndex,
  rowsPerPage = 10,
  isLoading,
  onRowClick,
  onPageChange,
}: TableProps<T>) {
  const handleChangePage = (_event: unknown, newPageIndex: number) => {
    onPageChange(newPageIndex)
  }

  function TableHead({ fields }: { fields: Field<T>[] }) {
    return (
      <MuiTableHead>
        <TableRow>
          {fields.map((field, index) => (
            <TableCell key={index} align={"left"}>
              <TableSortLabel>{field.label}</TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </MuiTableHead>
    )
  }

  return (
    <Box sx={{ width: { xs: "100%", md: 750 }, position: "relative" }}>
      {isLoading && (
        <CircularProgress
          style={{
            position: "absolute",
            left: "calc(50% - 20px)",
            top: "calc(50% - 20px)",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        />
      )}
      {rows &&
        (rows.length === 0 ? (
          <h3 style={{ textAlign: "center", padding: 20 }}>
            No results found.
          </h3>
        ) : (
          <Paper sx={{ width: "100%", mb: 2, position: "relative" }}>
            <TableContainer>
              <MuiTable
                sx={{ minWidth: { md: 750 } }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <TableHead fields={fields} />
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow
                        id={`row-${index}`}
                        className="table-row"
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: "pointer" }}
                      >
                        {fields.map((c, index) => (
                          <TableCell
                            className={`table-cell-${String(c.id)}`}
                            align="left"
                            key={index}
                            onClick={() => onRowClick(row)}
                          >
                            {c.mapper ? c.mapper(row[c.id]) : row[c.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </MuiTable>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={totalRowCount}
              rowsPerPage={rowsPerPage}
              page={pageIndex}
              onPageChange={handleChangePage}
            />
          </Paper>
        ))}
    </Box>
  )
}
