import React, { FormEvent, useState } from "react"
import {
  Select,
  MenuItem,
  Button,
  TextareaAutosize,
  Alert,
  Snackbar,
} from "@mui/material"
import { centeredStyle } from "./global"
import { supabase } from "./supabase"
import { REPORTED_PROBLEMS_TABLE } from "./constants"
import { TextField } from "./components/TextField"

interface ReportProblemWidgetProps
  extends Omit<_ReportProblemWidgetProps, "handleReportProblem"> {}

export interface ReportedProblem {
  type: string
  reporterName?: string
  reporterEmail?: string
  comments?: string
  pageUrl: string
}

export const ReportProblemWidget: React.FC<ReportProblemWidgetProps> = (
  props,
) => {
  const handleReportProblem = async (p: ReportedProblem) => {
    await supabase.from(REPORTED_PROBLEMS_TABLE).insert(p)
  }

  return (
    <_ReportProblemWidget
      handleReportProblem={handleReportProblem}
      {...props}
    />
  )
}

export interface ProblemType {
  value: string
  label: string
}

interface _ReportProblemWidgetProps {
  labelWhenNoSelection?: string
  typeOptions: ProblemType[]
  showUserFields?: boolean
  handleReportProblem: (p: ReportedProblem) => Promise<void>
}

export const _ReportProblemWidget: React.FC<_ReportProblemWidgetProps> = ({
  labelWhenNoSelection = "Report a problem",
  typeOptions,
  showUserFields = true,
  handleReportProblem,
}) => {
  const NONE = "none"
  const [selectedOption, setSelectedOption] = useState(NONE)
  const [open, setOpen] = useState(false)

  const handleOptionChange = (value: string) => {
    setSelectedOption(value)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    e.currentTarget.reset()
    await handleReportProblem({
      type: formData.get("type") as string,
      reporterName: formData.get("reporterName") as string,
      reporterEmail: formData.get("reporterEmail") as string,
      comments: formData.get("comments") as string,
      pageUrl: formData.get("pageUrl") as string,
    })
    setOpen(true)
    setSelectedOption(NONE)
  }

  return (
    <form onSubmit={handleSubmit} style={centeredStyle}>
      <Select
        name="type"
        style={{ margin: 5, backgroundColor: "white" }}
        value={selectedOption}
        onChange={(e) => handleOptionChange(e.target.value)}
      >
        <MenuItem value={NONE}>{labelWhenNoSelection}</MenuItem>
        {typeOptions.map((t) => (
          <MenuItem key={t.value} value={t.value}>
            {t.label}
          </MenuItem>
        ))}
        <MenuItem value={"other"}>Other</MenuItem>
      </Select>
      <input type="hidden" name="pageUrl" value={window.location.href} />
      {selectedOption !== NONE && (
        <>
          {showUserFields && (
            <>
              <TextField
                name="reporterName"
                style={{ width: 300, margin: 5 }}
                label="Your Name (optional)"
              />
              <TextField
                name="reporterEmail"
                style={{ width: 300, margin: 5 }}
                label="Your Email (optional)"
              />
            </>
          )}
          <TextareaAutosize
            name="comments"
            style={{ width: 300, height: 100, margin: 5 }}
            placeholder="Additional information"
          />
          <Button
            id="report-problem-submit"
            style={{ margin: 5 }}
            variant="contained"
            disabled={selectedOption === NONE}
            type="submit"
          >
            Submit
          </Button>
        </>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Problem reported! Thank you!
        </Alert>
      </Snackbar>
    </form>
  )
}
