import { Box, Typography } from "@mui/material"
import React from "react"

interface PageProps {
  title?: string | React.ReactNode
  subtitle?: string | React.ReactNode
  children?: React.ReactNode
  verticallyCentered?: boolean
  [key: string]: any
}

export const Page: React.FC<PageProps> = ({
  title,
  subtitle,
  children,
  verticallyCentered,
  ...props
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        width: "100%",
      }}
      sx={{ margin: { xs: 1, md: "40px" } }}
      {...props}
    >
      {typeof title === "string" ? (
        <Typography
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
          id="page-title"
        >
          {title}
        </Typography>
      ) : (
        title
      )}
      <Typography
        variant="h6"
        align="center"
        color="text.secondary"
        style={{ marginBottom: 20, maxWidth: 650 }}
      >
        {subtitle}
      </Typography>
      <div
        style={{
          margin: "20px 10px",
          padding: 10,
          width: "100%",
          justifyContent: verticallyCentered ? "center" : "flex-start",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
        }}
      >
        {children}
      </div>
    </Box>
  )
}
