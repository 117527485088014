import { Button } from "@mui/material"
import { useState } from "react"
import { Page } from "./Page"
import { emailIsValid } from "./utils"
import { centeredStyle } from "./global"
import { TextField } from "./components/TextField"

export interface NewSignUp {
  firstName: string
  lastName: string
  school: string
  email: string
}

export const SignUpPage_ = ({
  title,
  submittedTitle,
  submittedText,
  school,
  submitText,
  handleSubmit,
}: {
  title: string
  submittedTitle: string
  submittedText: string
  school: string
  submitText: string
  handleSubmit: (newSignUp: NewSignUp) => Promise<void>
}) => {
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState<null | string>(null)

  const textFields = [
    {
      id: "firstName",
      label: "First Name",
    },
    {
      id: "lastName",
      label: "Last Name",
    },

    {
      id: "school",
      label: "School",
      value: school,
    },
    {
      id: "email",
      label: "Email",
    },
  ]

  const handleSubmit_ = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const firstName = formData.get("firstName") as string
    const lastName = formData.get("lastName") as string
    const school = formData.get("school") as string
    const email = formData.get("email") as string
    if (!emailIsValid(email)) {
      setError("Please use your district email")
      return
    } else {
      await handleSubmit({ firstName, lastName, school, email })
      setSubmitted(true)
    }
  }

  return (
    <Page title={!submitted ? title : submittedTitle}>
      {submitted ? (
        <div>
          <p>{submittedText}</p>
        </div>
      ) : (
        <div style={centeredStyle}>
          <form
            onSubmit={handleSubmit_}
            style={{ width: 350, ...centeredStyle }}
          >
            {textFields.map((textField) => (
              <TextField
                key={textField.id}
                name={textField.id}
                id={`textfield-${textField.id}`}
                label={textField.label}
                margin="normal"
                fullWidth={true}
                value={textField.value}
                InputProps={{
                  readOnly: textField.value ? true : false,
                }}
                required
              />
            ))}
            {error && <div style={{ color: "red" }}>{error}</div>}
            <br />
            <Button type="submit" variant="contained" color="primary">
              {submitText}
            </Button>
          </form>
        </div>
      )}
    </Page>
  )
}
