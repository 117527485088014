import { useEffect, useState } from "react"
import {
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material"
import SurveySection from "./SurveySection"
import { supabase } from "./supabase"
import _ from "lodash"
import { useNavigate, useParams } from "react-router-dom"
import { Page } from "./Page"
import { Principal } from "./FindPrincipalPage"
import LoadingPage from "./LoadingPage"
import {
  COMMENTS_TABLE,
  CONTACT_US_PATH,
  NEW_RATING_THANK_YOU_PATH,
  PROMPT_SCORES_TABLE,
  STAFF_TABLE,
  SURVEY_PROMPTS_TABLE,
  SURVEY_SECTIONS_TABLE,
  SURVEY_SUBMISSIONS_TABLE,
} from "./constants"
import { makeQuery } from "./utils/dbUtils"
import { centeredMobileStyle } from "./global"

export interface PromptScore {
  submissionId: number
  promptId: number
  value: number
}

export interface Section {
  id: number
  title: string
}

export interface Prompt {
  id: number
  sectionId: number
  text: string
}

export const CreateRatingPage = () => {
  const { principalId } = useParams<{ principalId: string }>()
  const [principal, setPrincipal] = useState<Principal | null>(null)
  const [hasPreviousReviews, setHasPreviousReviews] = useState<boolean | null>(
    null,
  )
  const [sections, setSections] = useState<Section[]>([])
  const [prompts, setPrompts] = useState<Prompt[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const { data: prompts } = await supabase
        .from(SURVEY_PROMPTS_TABLE)
        .select("*")
        .order("id", { ascending: true })
      setPrompts(prompts as Prompt[])

      const { data: sections } = await supabase
        .from(SURVEY_SECTIONS_TABLE)
        .select("*")
        .order("id", { ascending: true })
      setSections(sections as Section[])

      const { data: principal } = await supabase
        .from(STAFF_TABLE)
        .select("*")
        .eq("id", principalId)
        .single()

      setPrincipal(principal)

      const {
        data: { user },
      } = await supabase.auth.getUser()

      const { data: surveySubmission } = await supabase
        .from(SURVEY_SUBMISSIONS_TABLE)
        .select("*")
        .eq("createdBy", user?.id)
        .limit(1)

      setHasPreviousReviews(surveySubmission && surveySubmission?.length > 0)
    })()
  }, [])

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    const formData = Object.fromEntries(new FormData(event.currentTarget))

    const groupedFormData = _.groupBy(
      Object.entries(formData),
      ([key]) => key.split("-")[0],
    )

    const {
      data: { user },
    } = await supabase.auth.getUser()

    const surveySubmission = await makeQuery<{ id: number }>(
      supabase
        .from(SURVEY_SUBMISSIONS_TABLE)
        .insert({ createdBy: user?.id, principalId })
        .select("id")
        .single(),
    )

    const promptScores = groupedFormData.prompt.map(([key, value]) => ({
      promptId: parseInt(key.split("-")[1]),
      value: parseInt(String(value)),
      submissionId: surveySubmission.id,
      createdBy: user?.id,
    }))

    const comments = groupedFormData.comment
      .map(([key, value]) => ({
        sectionId: parseInt(key.split("-")[1]),
        text: String(value),
        submissionId: surveySubmission.id,
        createdBy: user?.id,
      }))
      .filter((comment) => comment.text)

    await supabase.from(PROMPT_SCORES_TABLE).insert(promptScores)
    await supabase.from(COMMENTS_TABLE).insert(comments)
    navigate(NEW_RATING_THANK_YOU_PATH)
  }

  return (
    <_CreateRatingPage
      principal={principal}
      hasPreviousReviews={hasPreviousReviews}
      sections={sections}
      prompts={prompts}
      handleSubmit={handleSubmit}
    />
  )
}

interface _CreateRatingPageProps {
  principal: Principal | null
  hasPreviousReviews: boolean | null
  sections: Section[]
  prompts: Prompt[]
  handleSubmit: (event: any) => void
}

export const _CreateRatingPage = ({
  principal,
  hasPreviousReviews,
  sections,
  prompts,
  handleSubmit,
}: _CreateRatingPageProps) => {
  const [hasConfirmed, setHasConfirmed] = useState(false)

  if (!principal) {
    return <LoadingPage />
  }

  if (hasPreviousReviews) {
    return (
      <Page title="Sorry, you can't submit more than one review.">
        <Typography variant="h5">
          If you believe this is an error, please let us know!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
          href={CONTACT_US_PATH}
        >
          Contact Us
        </Button>
      </Page>
    )
  }

  return (
    <Page title={`New Review for ${principal?.fullName}`}>
      <form onSubmit={handleSubmit} id="rating-form">
        <Grid container rowSpacing={3}>
          {sections.map(({ title, id }, index) => (
            <Grid item xs={12} key={index}>
              <SurveySection
                sectionId={id}
                title={title}
                prompts={prompts.filter((p) => p.sectionId === id)}
              />
            </Grid>
          ))}
          <Grid item xs={12} sx={centeredMobileStyle}>
            <FormGroup style={{ margin: "30px 0px" }} id="confirm-checkbox">
              <FormControlLabel
                sx={{ alignItems: { xs: "start", md: "center" } }}
                control={
                  <Checkbox onChange={() => setHasConfirmed(!hasConfirmed)} />
                }
                label="By clicking this box, I confirm that my review is founded on factual observations and insights, rooted in my personal experiences and direct encounters."
              />
            </FormGroup>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!hasConfirmed}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Page>
  )
}

export default CreateRatingPage
