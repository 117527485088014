import "./App.css"
import { GlobalStyles, CssBaseline, Alert } from "@mui/material"
import { FloatingButton } from "./FloatingButton"
import { Footer } from "./Footer"
import { NavBar } from "./NavBar"
import { Session } from "@supabase/supabase-js"
import { NavigateFunction } from "react-router-dom"
import { StateReadySignUpModal } from "./components/StateReadySignUpModal"
import { useState } from "react"
import "./assets/brandonRegular.css"

export const AppWrapper = ({
  children,
  session,
  navigate,
}: {
  children: React.ReactNode
  session: Session | null
  navigate: NavigateFunction
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Alert
        variant="filled"
        style={{
          borderRadius: 0,
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "BrandonRegular",
          fontSize: "1rem",
        }}
        icon={false}
      >
        Rate Your SY23-24 Principal Now!
      </Alert>
      <NavBar session={session} navigate={navigate} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: 40,
          alignItems: "center",
          backgroundImage: "url(/backgroundPaper.jpeg)",
          backgroundColor: "rgba(255, 255, 255, 0.65)",
          backgroundBlendMode: "lighten",
          backgroundSize: "70%",
          width: "100%",
        }}
      >
        <div
          style={{
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {children}
        </div>
        <Footer />
      </div>
      {!session && <FloatingButton navigate={navigate} />}
      <StateReadySignUpModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  )
}
