import PrincipalsTable from "./PrincipalTable"
import { useNavigate } from "react-router-dom"
import { ADD_PRINCIPAL_PATH, PRE_REVIEW_PATH } from "./constants"
import { Page } from "./Page"
import { Link } from "@mui/material"

export const SelectPrincipalPage = () => {
  const navigate = useNavigate()

  const handlePrincipalClick = (principalId: number) => {
    navigate(`${PRE_REVIEW_PATH}/${principalId}`)
  }

  return (
    <Page title="Select Your Principal">
      <PrincipalsTable onRowClick={handlePrincipalClick} />
      <nav>
        <Link href={ADD_PRINCIPAL_PATH}>My principal isn't listed here</Link>
      </nav>
    </Page>
  )
}
