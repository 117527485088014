import { useNavigate, useParams } from "react-router-dom"
import { Page } from "./Page"
import PrincipalsTable, { principalFields } from "./PrincipalTable"
import { PRINCIPAL_PATH, READY_PRINCIPALS_VIEW } from "./constants"
import { averageRatingField } from "./PrincipalsByRatingPage"
import { PRINCIPALS_NOT_SHOWN } from "./stringConstants"

export const PrincipalsByDistrictPage = () => {
  const { district } = useParams()
  const navigate = useNavigate()
  return (
    <Page title={`Highest Rated Principals in ${district}`}>
      <PrincipalsTable
        fields={[...principalFields, averageRatingField]}
        searchEvenWhenBlank={true}
        databaseTable={READY_PRINCIPALS_VIEW}
        defaultSortField="averageRating"
        queryModifier={(query) =>
          query.eq("district", district).not("averageRating", "is", null)
        }
        onRowClick={(id) => navigate(`${PRINCIPAL_PATH}/${id}`)}
      />
      <p>{PRINCIPALS_NOT_SHOWN}</p>
    </Page>
  )
}
