import { useState } from "react"
import { AppBar, Box, Button, Drawer, Link, Toolbar } from "@mui/material"
import { Session } from "@supabase/supabase-js"
import { supabase } from "./supabase"
import { NavigateFunction } from "react-router-dom"
import {
  ABOUT_US_PATH,
  DONATE_PATH,
  FAQS_PATH,
  GREEN,
  NAVY,
  PRINCIPAL_RESOURCES_PATH,
  STATIC_PATH,
} from "./constants"
import "./assets/brandonMedium.css"
import { NavBarMenu } from "./widgets/NavBarMenu"
import { GridMenuIcon } from "@mui/x-data-grid"

interface NavBarProps {
  session: Session | null
  navigate: NavigateFunction
}

export const NavBar = ({ session, navigate }: NavBarProps) => {
  const [drawerIsOpen, setDrawerOpen] = useState(false)

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerIsOpen)
  }

  const links = [
    { name: "About Us", path: ABOUT_US_PATH },
    { name: "Resources for Principals", path: PRINCIPAL_RESOURCES_PATH },
    { name: "FAQs", path: FAQS_PATH },
    { name: "Donate", path: DONATE_PATH },
  ]

  const renderLinks = () =>
    links.map((link, index) => (
      <Link
        key={index}
        variant="button"
        href={link.path}
        sx={{
          display: "block",
          my: 1,
          mx: 1.5,
          ...styles.link,
        }}
      >
        {link.name}
      </Link>
    ))

  const renderSignOutButton = () =>
    session && (
      <Button
        id="sign-out-button"
        href="#"
        variant="outlined"
        sx={{ my: 1, mx: 1.5 }}
        onClick={handleButtonClick}
        style={{ color: "white" }}
        color="inherit"
      >
        Sign Out
      </Button>
    )

  const handleButtonClick = async () => {
    if (session) {
      supabase.auth.signOut()
      navigate("/")
    } else {
      navigate("/login")
    }
  }

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
      style={styles.appbar}
    >
      <Toolbar style={styles.toolbar}>
        <Link href={STATIC_PATH} style={{ lineHeight: 0 }}>
          <img src="/logo.png" alt="logo" style={styles.logo} />
        </Link>
        <Box sx={{ display: { xs: "none", sm: "flex" }, ...styles.links }}>
          <NavBarMenu />
          {renderLinks()}
          {renderSignOutButton()}
        </Box>
        <Button
          onClick={handleDrawerToggle}
          sx={{ display: { xs: "block", sm: "none" }, color: "white" }}
        >
          <GridMenuIcon />
        </Button>
      </Toolbar>
      <Drawer
        anchor="right"
        open={drawerIsOpen}
        onClose={handleDrawerToggle}
        PaperProps={{ style: { backgroundColor: NAVY } }}
      >
        <div onClick={handleDrawerToggle}>
          {renderLinks()}
          {renderSignOutButton()}
        </div>
      </Drawer>
    </AppBar>
  )
}

type CustomCSSProperties = React.CSSProperties & {
  "&:hover"?: React.CSSProperties
}

export const linkStyle: CustomCSSProperties = {
  color: "white",
  fontFamily: "BrandonMedium",
  fontSize: 18,
  textTransform: "none",
  textDecoration: "none",
  "&:hover": {
    color: GREEN,
    textDecoration: "none",
  },
}

const styles: { [key: string]: React.CSSProperties } = {
  appbar: {
    backgroundColor: NAVY,
    border: 0,
    height: 125,
    justifyContent: "center",
    alignItems: "center",
  },
  toolbar: {
    margin: "0px !important",
    padding: "0px !important",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 226,
    height: 113,
  },
  links: {
    width: 755,
    justifyContent: "center",
    alignItems: "center",
  },
  link: linkStyle,
}
