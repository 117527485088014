import { useEffect, useState } from "react"
import { supabase } from "./supabase"
import { NOTIFICATION_SIGN_UPS_TABLE } from "./constants"

interface NotificationSignUp {
  id: number
  createdAt: string
  name: string
  email: string
  extra: { [key: string]: string }
  type: string
}

export const NotificationSignUpsPage = () => {
  const [signUps, setSignUps] = useState<NotificationSignUp[]>([])
  useEffect(() => {
    ;(async () => {
      const { data: signUps } = (await supabase
        .from(NOTIFICATION_SIGN_UPS_TABLE)
        .select("*")
        .order("createdAt", { ascending: false })) as {
        data: NotificationSignUp[]
      }

      setSignUps(signUps || [])
    })()
  }, [])

  return (
    <div>
      <h1>Notification Sign Ups</h1>
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>createdAt</th>
            <th>name</th>
            <th>email</th>
            <th>extra</th>
            <th>type</th>
          </tr>
        </thead>
        <tbody>
          {signUps.map((signUp, index) => (
            <tr key={signUp.id} id={`signUp-${index}`}>
              <td>{signUp.id}</td>
              <td>{signUp.createdAt}</td>
              <td>{signUp.name}</td>
              <td>{signUp.email}</td>
              <td>{JSON.stringify(signUp.extra)}</td>
              <td>{signUp.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
