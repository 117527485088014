import PromptRatingWidget from "./PromptRatingWidget"
import { AveragePromptScore } from "./ReadyPrincipalPage"

interface AverageRatingSectionProps {
  averagePromptScores: AveragePromptScore[]
}

export const AverageRatingSection: React.FC<AverageRatingSectionProps> = ({
  averagePromptScores,
}) => {
  return (
    <div>
      {averagePromptScores.map((averagePromptScore, index) => (
        <div key={index} style={{ margin: "15px 0px" }}>
          <PromptRatingWidget
            promptText={averagePromptScore.promptText}
            rating={averagePromptScore.value}
          />
        </div>
      ))}
    </div>
  )
}
