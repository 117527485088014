import { Button } from "@mui/material"
import { levelsOfAgreement } from "../SurveySection"
import { useState } from "react"
import _ from "lodash"

export const RatingButtonGroup = ({ promptId }: { promptId: number }) => {
  const [rating, setRating] = useState<number | null>(null)

  return (
    <div style={{ height: 50, display: "flex" }}>
      {levelsOfAgreement.map((_level, levelIndex) => (
        <div key={levelIndex}>
          <Button
            className="rating-button"
            style={{
              width: 120,
              height: 50,
              display: "flex",
              borderRadius: "revert",
            }}
            variant={rating === levelIndex ? "contained" : "outlined"}
            onClick={() => setRating(levelIndex)}
          >
            {levelIndex + 1}
          </Button>
          <input
            style={{ opacity: 0, position: "absolute" }}
            type="radio"
            required
            name={`prompt-${promptId}`}
            value={levelIndex}
            onChange={_.noop}
            checked={rating === levelIndex}
          />
        </div>
      ))}
    </div>
  )
}
