import { CSSProperties } from "react"
import "./PageTitle.css"

export interface PageTitleProps {
  firstWord: string
  restOfTitle: string
}

export const PageTitle = ({ firstWord, restOfTitle }: PageTitleProps) => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1 style={styles.firstWord}>{firstWord}</h1>
      <div style={styles.restOfTitle}>{restOfTitle}</div>
    </div>
  )
}

const styles: Record<string, CSSProperties> = {
  firstWord: {
    fontFamily: "BrandonSmith",
    fontSize: 80,
    margin: 0,
    lineHeight: 1,
  },
  restOfTitle: {
    fontSize: 39,
    textTransform: "uppercase",
    lineHeight: 1.1,
  },
}
