import { Button, Link, Menu as MuiMenu, MenuItem } from "@mui/material"
import { useState } from "react"
import {
  DISTRICTS_BY_RATING_PATH,
  FIND_A_PRINCIPAL_PATH,
  NAVY,
  PRINCIPALS_BY_RATING_PATH,
  YOUR_PRINCIPAL_MODE,
} from "../constants"
import { linkStyle } from "../NavBar"

export const GET_STARTED_LINK_Z_INDEX_ADD = 1

export const NavBarMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)
  let closeTimeoutId: NodeJS.Timeout | null = null

  const handleMouseEnterButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (!!closeTimeoutId) {
      clearTimeout(closeTimeoutId)
    }
    closeTimeoutId = setTimeout(() => {
      setAnchorEl(null)
    }, 100)
  }

  const handleMenuEnter = () => {
    if (!!closeTimeoutId) {
      clearTimeout(closeTimeoutId)
    }
  }

  return (
    <div>
      <Button
        sx={{
          zIndex: (theme) => theme.zIndex.modal + GET_STARTED_LINK_Z_INDEX_ADD,
          ...linkStyle,
        }}
        onMouseOver={handleMouseEnterButton}
        onMouseLeave={handleClose}
      >
        Let's Get Started
      </Button>
      <Menu
        isOpen={isOpen}
        handleClose={handleClose}
        anchorEl={anchorEl}
        handleMenuEnter={handleMenuEnter}
      />
    </div>
  )
}

export interface MenuProps {
  isOpen: boolean
  handleClose: () => void
  anchorEl: HTMLElement | null
  handleMenuEnter: () => void
}

export const Menu = ({
  isOpen,
  handleClose,
  anchorEl,
  handleMenuEnter,
}: MenuProps) => {
  const links = [
    { name: "Browse Highest Rated Districts", path: DISTRICTS_BY_RATING_PATH },
    {
      name: "Browse Highest Rated Principals",
      path: PRINCIPALS_BY_RATING_PATH,
    },
    { name: "Find a Principal", path: FIND_A_PRINCIPAL_PATH },
    {
      name: "Rate Your Principal",
      path: `${FIND_A_PRINCIPAL_PATH}/${YOUR_PRINCIPAL_MODE}`,
    },
  ]

  return (
    <MuiMenu
      open={isOpen}
      onClose={handleClose}
      anchorEl={anchorEl}
      MenuListProps={{
        style: { backgroundColor: NAVY },
        onMouseEnter: handleMenuEnter,
        onMouseLeave: handleClose,
      }}
    >
      {links.map((link, index) => (
        <MenuItem
          key={index}
          onClick={handleClose}
          style={{ width: "100%" }}
          sx={linkStyle}
        >
          <Link
            href={link.path}
            sx={{
              ...linkStyle,
              color: "unset",
              width: "100%",
              textAlign: "end",
            }}
          >
            {link.name}
          </Link>
        </MenuItem>
      ))}
    </MuiMenu>
  )
}
