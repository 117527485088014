import { useEffect, useState } from "react"
import { supabase } from "./supabase"
import { REPORTED_PROBLEMS_TABLE } from "./constants"
import { ReportedProblem } from "./ReportProblemWidget"

export const ReportedProblemsPage = () => {
  const [problems, setProblems] = useState<ReportedProblem[]>([])
  useEffect(() => {
    ;(async () => {
      const { data: problems } = (await supabase
        .from(REPORTED_PROBLEMS_TABLE)
        .select("*")
        .order("createdAt", { ascending: false })) as {
        data: ReportedProblem[]
      }

      setProblems(problems)
    })()
  }, [])

  return (
    <div>
      <h1>Reported Problems</h1>
      <table>
        <thead>
          <tr>
            <th>type</th>
            <th>reporterName</th>
            <th>reporterEmail</th>
            <th>comments</th>
            <th>pageUrl</th>
          </tr>
        </thead>
        <tbody>
          {problems.map((problem, index) => (
            <tr key={index} id={`problem-${index}`}>
              <td>{problem.type}</td>
              <td>{problem.reporterName}</td>
              <td>{problem.reporterEmail}</td>
              <td>{problem.comments}</td>
              <td>{problem.pageUrl}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
