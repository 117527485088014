import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { Page } from "./Page"
import { supabase } from "./supabase"
import { useNavigate } from "react-router-dom"
import { DISTRICTS_VIEW, PRINCIPALS_BY_DISTRICT_PATH } from "./constants"
import { Field, Table } from "./Table"
import { PageTitle } from "./widgets/PageTitle"
import { TextField } from "./components/TextField"

export interface District {
  name: string
  state: string
}

export const fields: Field<District>[] = [
  {
    id: "name",
    numeric: false,
    label: "District Name",
  },
  {
    id: "state",
    numeric: false,
    label: "State",
  },
]

const rowsPerPage = 10

export const DistrictSearchPage = () => {
  const [districts, setDistricts] = useState<District[] | undefined>()
  const [searchText, setSearchText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      if (searchText) {
        setIsLoading(true)
        const { data: districts } = (await supabase
          .from(DISTRICTS_VIEW)
          .select("*")
          .ilike("name", `%${searchText}%`)
          .range(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage - 1)
          .limit(rowsPerPage)
          .order("name")) as { data: District[] }

        setDistricts(districts)
        setIsLoading(false)
      }
    })()
  }, [searchText])

  return (
    <Page
      title={<PageTitle firstWord="Search" restOfTitle="for Your District" />}
    >
      <TextField
        id="search-input"
        label="District Name"
        value={searchText}
        sx={{ width: { xs: "100%", md: 500 } }}
        style={{ marginBottom: 30 }}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Table<District>
        fields={fields}
        rows={districts}
        totalRowCount={districts?.length ?? 0}
        pageIndex={pageIndex}
        isLoading={isLoading}
        onRowClick={(district) =>
          navigate(`${PRINCIPALS_BY_DISTRICT_PATH}/${district.name}`)
        }
        onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
      />
      <Button
        id="districts-by-rating-btn"
        onClick={() => {
          navigate("/districts-by-rating")
        }}
        variant="text"
      >
        See Districts With Highest Ratings
      </Button>
    </Page>
  )
}
