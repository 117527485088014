import { Button, Typography } from "@mui/material"
import { Page } from "../Page"

export const DonatePage = () => {
  return (
    <Page
      title={
        <Typography variant="h3" style={{ fontWeight: 600 }}>
          Donations Make Our Work Possible
        </Typography>
      }
      subtitle={
        <div>
          Rate Your Principals is run by{" "}
          <a href="https://www.teachergimbelcorporation.org/">
            TeacherGimbel Corporation
          </a>
          , a non-profit that runs solely on donations. The goal of this
          organization is to serve teachers by elevating their concerns to help
          get your needs met. If you’ve enjoyed this service we’d love if you
          could donate even a few dollars to let us continue to grow. We are
          committed to creating a better workplace for teachers and you can help
          us.
        </div>
      }
    >
      <Button
        href="https://www.paypal.com/donate/?hosted_button_id=RP7Y5PGWVLVQG"
        variant="contained"
        color="primary"
        style={{ fontSize: "xxx-large", marginTop: 25, textTransform: "none" }}
      >
        Donate Now
      </Button>
    </Page>
  )
}
