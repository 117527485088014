import { Button } from "@mui/material"
import { SELECT_PRINCIPAL_PATH } from "./constants"
import { Page } from "./Page"

const PrincipalNotFoundPage: React.FC = ({}) => {
  return (
    <Page
      title="We couldn't find your principal in our database."
      subtitle="But don't worry! You can still review them, you'll just need to click below to find them."
    >
      <nav style={{ display: "flex", flexDirection: "column" }}>
        <Button variant="contained" href={SELECT_PRINCIPAL_PATH}>
          Find your principal
        </Button>
      </nav>
    </Page>
  )
}

export default PrincipalNotFoundPage
